import React, { Component, useEffect, useState } from "react";
import customFetch from "./apicall/api";
import DetailComponent from "./PayloadviewDetail";
import { filterData, logout, generateId } from "./Utility";
import {
  get_payload_views,
  update_payload,
  get_mongo_payload,
  update_mongopayload,
  delete_mongofield,
  update_mongogrouppayload,
} from "./Services";
import {
  Select,
  Modal,
  Button,
  Input,
  DatePicker,
  Upload,
  message,
} from "antd";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  SettingOutlined,
  ReloadOutlined,
  FileAddOutlined,
  FileExcelOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { ThemeContext } from "../context/IsLoader";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "../assets/css/payload_view.css";
import {
  QueryBuilder,
  RuleGroupType,
  ValueEditor,
  ValueEditorProps,
} from "react-querybuilder";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ReactJson from "react-json-view";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import "../assets/css/styles.css";
import "../assets/css/example-styles.css";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import DragIcon from "../assets/images/draggable.png";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = {};

const { TextArea } = Input;
const dateFormatList = "DD/MM/YYYY";
const generate_Id1 = generateId(10);
export default class PayloadView extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      payload_config: props.location.state.payload_config,
      defaultpayload_config: props.location.state.payload_config,
      api_token: props.location.state.api_token,
      effective_designation_id: props.location.state.effective_designation_id,
      payload_data: [],
      payloadglobal_data: [],
      init_outputdata: [],
      disabled: false,
      isspin: false,
      isLoginuser: props.location.state.isLoginuser,
      sortOrder: "asc",
      otherScreen: props.location.state.otherScreen,
      no_of_page: "",
      filterDate: "",
      selectDate: "",
      isDateSel: "",
      DateSelMessage: false,
      view_name: props.location.state.view_name,
      search_fields: [],
      search_click: 0,
      isExporting: false,
      subpayload_config: "",
      unique_groups: "",
      filters: {},
      supervisor_branch_code: "",
      isSettings: false,
      HeaderData: [],
      checkedCheckboxes: [],
      Reasonlist: [],
      Reasonlist_default: [],
      MassReasonlist: [],
      massUpdateData: [],
      selectedMassUpdateData: [],
      massUpdateInput: [],
      isMassupdate: false,
      group_id: "",
      DataForMassUpdate: [],
      CheckAll: false,
      load_more: 0,
      viewfilter: "",
      searchconfig:
        props.location.state.payload_config.payload_type == "M"
          ? props.location.state.payload_config.field_mapping[0]
              .search_query || {
              combinator: "and",
              not: "False",
              rules: [],
            }
          : [],
      defaultsearchconfig:
        props.location.state.payload_config.payload_type == "M"
          ? props.location.state.payload_config.field_mapping[0]
              .search_query || {
              combinator: "and",
              not: "False",
              rules: [],
            }
          : [],
      filtername: "",
      savedFilter: "",
      filtercrtupd: "",
      isMobile: false,
      query: { combinator: "and", rules: [] },
      Operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: ">", label: ">" },
        { name: "<", label: "<" },
        { name: ">=", label: ">=" },
        { name: "<=", label: "<=" },
        { name: "exists", label: "Exists" },
        { name: "TDHG", label: "Time Different > (In Hour)" },
        { name: "TDHL", label: "Time Different < (In Hour)" },
      ],
      disable_loadmore: false,
      disable_loadmore: false,
      disable_loadmore: false,
      layouts: JSON.parse(JSON.stringify(originalLayouts)),
      bodyLayouts: JSON.parse(JSON.stringify(originalLayouts)),
      isGetFirstdata: 0,
      CheckAll: false,
      isMassupdclick: false,
      isError: false,
      isErrorMessage: "",
      filter_name: "",
      dropdowncondition: [],
      isMobileChecked: false,
      mobilequery: "",
      isVisibleEdit: true,
      isMassUpdatePopup: false,
      updateUniqueData: [],
      sub_data:[],
      sumDetail: false,
      currentPosition: {
        xRate: 230,
        yRate: -510,
      },
      isExprow: false,
      ExpandedRow: [],
      removedCheckboxes:[],
    };
  }

  componentDidMount() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobiledata = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
      userAgent
    );
    this.setState({ isMobile: isMobiledata });
    console.log("isMobile", isMobiledata);

    const hasEditableElement = this.state.payload_config.field_mapping.some(
      (item) => item.isEdit === "1"
    );
    this.setState({ isVisibleEdit: hasEditableElement });

    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);

    if (this.state.supervisor_branch_code == "") {
      let generate_Id = generateId(10);
      const params = {
        userid: localStorage.getItem("in_userid"),
      };
      const queryParams = new URLSearchParams(params).toString();
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "",
          user_id: localStorage.getItem("in_userid"),
        },
      };
      // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
      const Reasonlist = customFetch(
        process.env.REACT_APP_URL + "/getrole?" + queryParams,
        requestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log("json.branch_name", json.branch_name);
          this.setState(
            {
              supervisor_branch_code: json.branch_code,
              contextuser_branchid: json.branch_name,
            },
            () => {
              console.log("data stored");
              if (!this.state.filterDate) {
                let inputDate = moment()
                  .subtract(6, "months")
                  .format("YYYY-MM-DD");
                this.setState(
                  {
                    filterDate: moment(inputDate).unix(),
                    defaultdate: inputDate,
                  },
                  () => {
                    this.getPayloads();
                  }
                );
              }
            }
          );
        });
    }

    const fetchData = async () => {
      try {
        for (const field of this.state.payload_config.field_mapping) {
          if (field.addtosum && field.addtosum == "1") {
            console.log("addtosum", field);
            this.setState({ addtosum: field });
          }

          if (field.datatype === "dropdownlist") {
            const generate_Id = generateId(10);
            const requestOptions = {
              method: "GET",
              headers: {
                "api-token": this.state.api_token,
                component_id: generate_Id1,
                apiId: generate_Id,
                api_group_id: "",
                component: "",
                user_id: localStorage.getItem("in_userid"),
              },
            };

            const response = await customFetch(
              process.env.REACT_APP_URL +
                "/get/dropdown/item/list?dropdown_id=" +
                field.dropdownlist,
              requestOptions
            );
            const result = await response.json();
            console.log("result-->", result);
            const bucket_field = field.BucketField.replace(/\./g, "_");
            if (!this.state.Reasonlist[field.BucketField]) {
              this.setState({ Reasonlist_default: { [bucket_field]: result } });
              console.log(
                "this.state.Reasonlist.length",
                this.state.Reasonlist[field.BucketField]
              );
              // this.setState(
              //   (prevState) => ({
              //     ...prevState,
              //     Reasonlist: {
              //       ...prevState.Reasonlist,
              //       [bucket_field]: result,
              //     },
              //   }),
              //   () => {
              //     // Log the entire state to check Reasonlist
              //     console.log("Updated State:", this.state.Reasonlist);
              //     //this.setquery();
              //   }
              // );
              this.setState({ Reasonlist: { [bucket_field]: result } });
              this.setState({ MassReasonlist: { [bucket_field]: result } });
            }
          }

          if (field.datatype === "group_id") {
            const generate_Id = generateId(10);
            const payload = [
              ["processed", "exists", "false"],
              ["issearch", "==", [{}]],
            ];
            const requestOptions = {
              method: "POST",
              headers: {
                "api-token": this.state.api_token,
                component_id: generate_Id1,
                apiId: generate_Id,
                api_group_id: "",
                component: "",
                user_id: localStorage.getItem("in_userid"),
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            };

            const response = await customFetch(
              process.env.REACT_APP_URL + "/mongo/get/callbacks/unique_groups",
              requestOptions
            );
            const json = await response.json();
            const convertedArray = json.map((item) => ({
              group_id: item.group_id,
            }));
            this.setState({ unique_groups: convertedArray });
          }
          //if (fields.exprow || fields.view_type == "AV") {
          if (this.state.payload_config.view_type == "AV") {
            this.getsubviewconfig(this.state.payload_config.agg_view);
          }
          if (field.massupdate == "1") {
            this.setState((prevState) => ({
              massUpdateData: [...prevState.massUpdateData, field],
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error here, e.g., show an error message to the user
      }
    };

    fetchData();

    if (this.state.otherScreen != null) {
      this.setState({
        no_of_page: 2,
      });
      this.context.setotherscreen(this.state.otherScreen);
      this.context.setno_of_page(2);
    }

    // if (this.state.isDateSel == "") {
    //   this.setState({ DateSelMessage: true });
    // }

    const filteredData = this.state.payload_config.field_mapping.map((item) => {
      // Extract only the required fields
      const { fieldname, BucketField } = item;
      return { fieldname, BucketField };
    });

    // console.log("this.state.payload_config.field_mapping", filteredData);
    // const fieldnames = this.state.payload_config.field_mapping.map(
    //   (item) => item.fieldname
    // );
    this.setState({
      HeaderData: filteredData,
    });

    this.getviewcolconfig();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.Reasonlist !== prevState.Reasonlist) {
      this.setquery();
    }
  }
  // componentDidUpdate(prevProps, prevState) {
  //   // Check if stateA has changed
  //   console.log("addtosum1", prevState.selectedMassUpdateData);
  //   console.log("addtosum2", this.state.selectedMassUpdateData);
  //   if (
  //     prevState.selectedMassUpdateData !== this.state.selectedMassUpdateData
  //   ) {
  //     console.log("addtosum", this.state.addtosum);
  //   }
  // }
  setquery = () => {
    const filtersearch = this.props.location.state.payload_config.field_mapping.filter(
      (item) => item.isSearch === "1"
    );
    const extractedDataArray = [];
    if (this.props.location.state.payload_config.payload_type == "M") {
      filtersearch.forEach((item) => {
        const name = item.BucketField;
        const label = item.fieldname;
        const datatype = item.datatype;
        if (item.datatype == "dropdownlist") {
          const valueEditorType = "select";
          console.log(
            "Reasonlist_data",
            this.state.Reasonlist && this.state.Reasonlist
          );
          console.log("Reasonlist_name", name);

          const transformedData =
            this.state.Reasonlist &&
            this.state.Reasonlist[name].map((item) => ({
              label: item.item_name,
              name: item.item_value,
            }));
          const values = transformedData;
          extractedDataArray.push({
            name,
            label,
            datatype,
            valueEditorType,
            values,
          });
        } else {
          extractedDataArray.push({ name, label, datatype });
        }
      });
      //setsearch_fields(extractedDataArray);
      this.setState({ search_fields: extractedDataArray });
    } else {
      this.setState({ search_fields: filtersearch });
    }
  };
  getviewcolconfig = () => {
    let generate_Id = generateId(10);

    let payload = [
      ["view_id", "==", this.state.payload_config.view_id],
      ["user_id", "==", localStorage.getItem("in_userid")],
    ];
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": this.state.api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "",
        user_id: localStorage.getItem("in_userid"),
      },
      body: JSON.stringify(payload),
    };
    // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
    const Reasonlist = customFetch(
      process.env.REACT_APP_URL + "/mongo/get/callbacks/store_user_view",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("json->", json.status);
        if (json.length > 0) {
          // const updatedData = this.state.payload_config.field_mapping.map(
          //   (item) => {
          //     // Check if the BucketField is present in data1
          //     const isBucketFieldInData1 = json[0].view_config.includes(
          //       item.BucketField
          //     );

          //     // Update isVisible property based on the presence in data1
          //     return {
          //       ...item,
          //       isVisible: isBucketFieldInData1 ? "1" : "0",
          //     };
          //   }
          // );

          // this.setState((prevState) => ({
          //   payload_config: {
          //     ...prevState.payload_config,
          //     field_mapping: updatedData,
          //   },
          // }));
          console.log("jsondata->", json);
          this.setState({
            savedFilter: json,
          });

          // this.setState({
          //   checkedCheckboxes: json[0].view_config,
          // });

          let chkdata = [];
          this.state.HeaderData.map((field, index) => {
            chkdata.push(field.BucketField);
          });
          console.log("BucketField", chkdata);
          this.setState({
            checkedCheckboxes: chkdata,
          });
        } else {
          console.log("else part");
          let chkdata = [];
          this.state.HeaderData.map((field, index) => {
            chkdata.push(field.BucketField);
          });
          console.log("BucketField", chkdata);
          this.setState({
            checkedCheckboxes: chkdata,
          });
        }
      });
  };
  handleCheckboxChangeupdate = (fieldName) => {
    const { updatecheckbox } = this.state;
    const updatedCheckboxes = [...updatecheckbox];

    if (updatedCheckboxes.includes(fieldName)) {
      // If the checkbox is already checked, uncheck it
      const index = updatedCheckboxes.indexOf(fieldName);
      updatedCheckboxes.splice(index, 1);
    } else {
      // If the checkbox is unchecked, check it
      updatedCheckboxes.push(fieldName);
    }

    this.setState({ updatecheckbox: updatedCheckboxes });
  };
  handleCheckboxChange = (fieldName) => {
    const { checkedCheckboxes } = this.state;
    const updatedCheckboxes = [...checkedCheckboxes];

    if (updatedCheckboxes.includes(fieldName)) {
      // If the checkbox is already checked, uncheck it
      const index = updatedCheckboxes.indexOf(fieldName);
      updatedCheckboxes.splice(index, 1);
    } else {
      // If the checkbox is unchecked, check it
      updatedCheckboxes.push(fieldName);
    }

    this.setState({ checkedCheckboxes: updatedCheckboxes });    
  };
  getsubviewconfig = async (exprow) => {
    const filtered = this.props.location.state.all_view.filter(
      (item) => item.view_id === exprow
    );
    console.log("filtered", filtered[0]);
    this.setState({
      subpayload_config: filtered[0],
    });
  };
  handleSort = () => {
    // Implement sorting logic based on the current sort order
    const sortedData = [...this.state.payload_data].sort((a, b) => {
      if (this.state.sortOrder === "asc") {
        return moment
          .unix(a.created_at)
          .format("MM/DD/YYYY HH:mm:ss")
          .localeCompare(
            moment.unix(b.created_at).format("MM/DD/YYYY HH:mm:ss")
          );
      } else {
        return moment
          .unix(b.created_at)
          .format("MM/DD/YYYY HH:mm:ss")
          .localeCompare(
            moment.unix(a.created_at).format("MM/DD/YYYY HH:mm:ss")
          );
      }
    });

    // Update the table data and sort order

    this.setState({
      sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc",
      payload_data: sortedData,
    });
    // setTableData(sortedData);
    // setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  DeletePayload = async (doc_id) => {
    try {
      const url =
        process.env.REACT_APP_URL +
        "/payload/move/" +
        this.state.payload_config.bucket_name +
        "/" +
        doc_id;
      let payload = null;
      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: payload,
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.getPayloads();
    }
  };
  DeleteMongoPayload = async (doc_id) => {
    try {
      const url =
        process.env.REACT_APP_URL + "/payload/movemongo/callbacks/" + doc_id;
      let payload = null;
      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: payload,
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.getPayloads();
    }
  };
  getPayloads = async () => {
    console.log("call getPayloads");
    const { contextuser_id } = this.context;

    let data;

    if (
      this.props.location.state.fetch_onload == "true" ||
      this.state.search_click == 1
    ) {
      this.setState({ isspin: true });
      let dbname, collectionname;
      if (
        !this.state.payload_config.db_name ||
        this.state.payload_config.db_name == ""
      ) {
        dbname = "callbacks";
      } else {
        dbname = this.state.payload_config.db_name;
      }
      if (
        !this.state.payload_config.collection_name ||
        this.state.payload_config.collection_name == ""
      ) {
        collectionname = "callback_payloads";
      } else {
        collectionname = this.state.payload_config.collection_name;
      }
      if (this.state.payload_config.payload_type == "M") {
        let defaultfilter = [];
        if (this.state.isLoginuser == "1") {
          this.state.payload_config.field_mapping.map((fieldata) => {
            if (fieldata.defaultfilter != "") {
              let splitfieldata = fieldata.defaultfilter.split(".");
              console.log("splitfieldata", this.state.contextuser_branchid);
              //console.log("splitfieldata", localStorage.getItem(splitfieldata[1]));
              defaultfilter.push({
                field: fieldata.BucketField,
                fieldvalue: localStorage.getItem(splitfieldata[1]),
                //operatordata: "==",
                operatordata: splitfieldata[0],
              });
            }
          });
        }
        console.log("payload_data", this.state.payload_data.length);
        let limit_per_call;
        if (this.state.payload_config.record_per_call) {
          limit_per_call = parseInt(this.state.payload_config.record_per_call);
        } else {
          limit_per_call = 50;
        }
        let offset = 1;
        if (this.state.load_more == 1) {
          offset = this.state.payload_data.length + 1;
        }
        let filter_bucketfield = this.state.payload_config.field_mapping.map(
          (item) => item.BucketField
        );

        // let filter_bucketfield = this.state.payload_config.field_mapping
        //   .filter(
        //     (item) =>
        //       !["block", "Downloadfile", "jsondata", "image"].includes(
        //         item.datatype
        //       )
        //   )
        //   .map((item) => item.BucketField);

        let filter_columns = "";
        if (this.state.payload_config.get_data_by) {
          if (this.state.payload_config.get_data_by == "C") {
            filter_columns = this.filterUniqueData(filter_bucketfield);
          }
        } else {
          filter_columns = this.filterUniqueData(filter_bucketfield);
        }
        // let searchbypair;
        // searchbypair = this.processData(
        //   JSON.parse(this.state.payload_config.search_by_pair)
        // );
        // console.log(
        //   "filter_bucketfield",
        //   this.checkPlaceholders(this.state.payload_config.search_by_pair)
        // );
        const hideloadmore =
          this.state.payload_config.hide_loadmore &&
          this.state.payload_config.hide_loadmore == "0"
            ? "1"
            : "0";
        const resdata = await get_mongo_payload(
          this.state.api_token,
          this.checkPlaceholders(this.state.payload_config.search_by_pair),
          this.state.filterDate,
          this.formatQuerybuild(this.state.searchconfig),
          dbname,
          collectionname,
          defaultfilter,
          this.state.isLoginuser,
          limit_per_call,
          offset,
          filter_columns,
          hideloadmore
        );
        const responsedata = await resdata;
        const outputFormatPath = this.state.payload_config
          .sourcedataoutputformat;
        console.log("responsedata", outputFormatPath);
        if (!responsedata.message && outputFormatPath) {
          data = this.getNestedValue(responsedata, outputFormatPath);
        } else {
          data = responsedata;
        }
      } else if (this.state.payload_config.payload_type == "A") {
        console.log("this.state.payload_config", this.state.payload_config);
        //const api = this.state.payload_config;
        const datasource = this.state.payload_config.datasource;

        const form = new FormData();
        const formdata = this.state.payload_config.datasource[0].formdata;
        formdata.forEach((item) => {
          const processedValue = this.checkPlaceholders(item.formdatavalue);
          console.log(
            `Processed value for ${item.formdatakey}: ${processedValue}`
          );
          form.append(item.formdatakey, processedValue);
        });
        console.log("formdata", formdata);

        const requestOptions = {
          method: "POST",
          headers: {
            "api-token": this.state.api_token,
          },
          body: form,
        };
        let url = "";
        if (datasource[0].url_type == "N") {
          url = `${process.env.REACT_APP_URL}/${datasource[0].api_url}`;
        } else {
          url = datasource[0].api_url;
        }
        const response = await customFetch(url, requestOptions);
        const responsedata = await response.json();
        const outputFormatPath = datasource[0].outputformat;
        if (!responsedata.message) {
          data = this.getNestedValue(responsedata, outputFormatPath);
        } else {
          data = responsedata;
        }
      } else {
        let defaultfilter = [];
        if (this.state.isLoginuser == "1") {
          this.state.payload_config.field_mapping.map((fieldata) => {
            if (fieldata.defaultfilter != "") {
              let splitfieldata = fieldata.defaultfilter.split(".");
              console.log("splitfieldata", splitfieldata);
              //console.log("splitfieldata", localStorage.getItem(splitfieldata[1]));
              defaultfilter.push({
                field: fieldata.BucketField,
                fieldvalue: localStorage.getItem(splitfieldata[1]),
                operatordata: splitfieldata[0],
              });
            }
          });
        }

        let searchData = [];
        this.state.payload_config.field_mapping.map((cell) => {
          if (cell.isSearch == 1 && this.state[cell.BucketField]) {
            searchData.push([
              cell.BucketField,
              "==",
              this.state[cell.BucketField],
            ]);
          }
        });

        data = await get_payload_views(
          this.state.api_token,
          this.state.payload_config.bucket_name,
          this.state.payload_config.event_name,
          defaultfilter,
          this.state.isLoginuser,
          this.state.filterDate,
          this.state.payload_config.event_location,
          searchData,
          this.state.searchconfig
        );
      }
      let parsed_data = [];
      if (!data.message && data.status != 0) {
        for (let item in data) {
          parsed_data.push({
            ...data[item],
            key: item,
          });
        }
        if (data.length == 0) {
          this.setState({ disable_loadmore: true });
        }
        this.setState({ isspin: false });
        console.log("this.state.payload_data", parsed_data);
        this.setState({
          init_outputdata: parsed_data,
        });
        if (
          this.state.payload_config.view_type &&
          this.state.payload_config.view_type == "AV"
        ) {
          parsed_data = this.processAggData(
            parsed_data,
            this.state.payload_config.field_mapping
          );
          console.log("parsed_data", parsed_data);
        }
        if (
          Array.isArray(this.state.payload_data) &&
          this.state.load_more == 1
        ) {
          const newArray = [...this.state.payload_data, ...parsed_data];
          const result = [];
          newArray.forEach((item, index) => {
            result[index.toString()] = item;
          });
          this.setState({ payload_data: result});
        } else {
          this.setState({
            payload_data: parsed_data
          });
        }
      } else {
        this.setState({
          isspin: false,
        });
        alert(data.message);
        console.log("datadata", data);
      }
    }
    this.setState({ load_more: 0 });
    if (this.state.search_click == 1) {
      console.log("call updateUniqueData");
      this.setState(
        {
          isGetFirstdata: 0,
        },
        () => this.getfirstrowstatus(this.state.payload_data)
      );
    }
    setTimeout(() => {
      const updateUniqueData = this.updateUniqueData();
      this.setState({ updateUniqueData: updateUniqueData }, () =>
        this.getconfigsum()
      );
    }, 1000);
  };
  processAggData = (data, config) => {
    let resultData = [...data]; // Clone the data array to avoid mutation
    let groupedData = null;

    // First check for 'GD' in aggregatecolumn
    const gdConfig = config.find((conf) => conf.aggregatecolumn === "GD");
    if (gdConfig) {
      const { BucketField } = gdConfig;

      // Group the data by the field specified in BucketField
      groupedData = resultData.reduce((acc, item) => {
        const fieldValue = BucketField.split(".").reduce((o, i) => o[i], item);
        if (!acc[fieldValue]) {
          acc[fieldValue] = [];
        }
        acc[fieldValue].push(item);
        return acc;
      }, {});

      // Flatten the grouped data back into an array
      resultData = Object.values(groupedData).flat();
    }

    // Then check for 'AD' in aggregatecolumn
    const adConfig = config.find((conf) => conf.aggregatecolumn === "AD");
    if (adConfig && groupedData) {
      const { BucketField, sumFieldlist } = adConfig;

      // Sum the data based on the grouped data
      const summedData = Object.entries(groupedData).map(([key, items]) => {
        const summedItem = { ...items[0] }; // Clone the first item in the group
        summedItem[BucketField] = items.reduce(
          (sum, item) => sum + item[BucketField],
          0
        );
        return summedItem;
      });

      resultData = summedData;
    }

    return resultData;
  };
  handleRowClick = (rowData) => {
    console.log("rowData", rowData);
    const subinput = this.getGroupForEntry(rowData,"RowClick");
    this.setState({
      ExpandedRow: subinput,
    });
    if (this.state.isMobile) {
      console.log("isMobileView", this.state.isMobile);
      this.setState({
        isExprow: true,
      });
    }
  };
  updaterowindex = (rowid) => {
    this.setState({ selectedrowindex: rowid }, () =>
      console.log("selectedrowindex", this.state.selectedrowindex)
    );
  };
  // New function to find and return all grouped data for a specific entry
  getGroupForEntry = (inputEntry,filtertype) => {
    console.log("getGroupForEntry_0", inputEntry);
    const data = filtertype=="RowClick"?this.state.init_outputdata:filtertype=="SubmitData"?this.state.payloadglobal_data:[];
    console.log("getGroupForEntry_1", data);
    const config = this.state.payload_config.field_mapping;
    console.log("getGroupForEntry_2", config);

    let groupedData = null;

    // First check for 'GD' in aggregatecolumn
    const gdConfig = config.find((conf) => conf.aggregatecolumn === "GD");
    if (gdConfig) {
      const { BucketField } = gdConfig;

      console.log("getGroupForEntry_5", gdConfig);
      // Group the data by the field specified in BucketField
      groupedData = data.reduce((acc, item) => {
        const fieldValue = BucketField.split(".").reduce((o, i) => o[i], item);
        if (!acc[fieldValue]) {
          acc[fieldValue] = [];
        }
        acc[fieldValue].push(item);
        return acc;
      }, {});

      // Find the group of the input entry
      let inputFieldValue = inputEntry[BucketField];

      // If the direct access returns undefined, check inside the nested "add_data_before" object
      if (inputFieldValue === undefined && inputEntry.add_data_before) {
        inputFieldValue = inputEntry.add_data_before[BucketField];
      }
      console.log("getGroupForEntry_3", inputFieldValue);
      console.log("getGroupForEntry_4", groupedData);

      return groupedData[inputFieldValue] || [];
    }

    return [];
  };
  getconfigsum = () => {
    if (this.state.addtosum && this.state.addtosum.sumFieldlist) {
      // acc.push({ key: [item.sumFieldlist], sum: [item.BucketField] });
      // const statusField = "order_status";
      // const amountField = "total_amount";
      const result = (
        this.state.dropdoenFiltered_data || this.state.payload_data
      ).reduce((acc, item) => {
        const status = item[this.state.addtosum.sumFieldlist] || null;
        const amount = item[this.state.addtosum.BucketField] || 0;

        if (status !== null) {
          let key = "";
          const keyname = this.getItemNameByValue(
            status,
            this.state.addtosum.sumFieldlist
          );
          // const keyname = status;
          // if (keyname != null) {
          //   key = keyname;
          // } else {
          // }
          key = status;
          const existingItem = acc.find((obj) => obj.key === key);
          if (existingItem) {
            existingItem.sum += amount;
          } else {
            acc.push({ key: key, sum: amount, name: keyname });
          }
        }

        return acc;
      }, []);
      const sumconfig = this.state.payload_config.field_mapping[0].sum_config;
      if (
        sumconfig &&
        sumconfig.filter((config) => !config.name || config.name.trim() === "")
          .length == 0
      ) {
        const config_sumresults = this.calculateConfigurations(
          sumconfig,
          result
        );
        console.log("config_sum", config_sumresults);
        this.setState({
          config_sum: config_sumresults,
        });
      } else {
        this.setState({
          config_sum: result,
        });
      }
    }
  };
  findSumByKey = (key, input) => {
    const entry = input.find((item) => item.key === parseInt(key));
    return entry
      ? { sum: entry.sum, name: entry.name }
      : { sum: 0, name: "Unknown" };
  };
  calculateConfigurations = (data, input) => {
    return data.map((config) => {
      let total = 0; // Initialize the total for each config
      let breakdown = [];
      config.items.forEach((item, index) => {
        const { sum, name } = this.findSumByKey(item.dropdown2, input);

        if (index === 0) {
          // Start with the first value
          total = sum;
        } else {
          // Apply operator for subsequent items
          switch (item.operator) {
            case "+":
              total += sum;
              break;
            case "-":
              total -= sum;
              break;
            case "*":
              total *= sum;
              break;
            case "/":
              total /= sum;
              break;
            default:
              total += sum; // If no operator, default to addition
              break;
          }
        }
        // Add each step's details to breakdown
        breakdown.push({
          dropdown2: item.dropdown2,
          sum,
          name,
          operator: item.operator,
        });
      });

      return {
        color: config.color,
        name: config.name,
        sum: total,
        breakdown: breakdown,
      };
    });
  };
  getItemNameByValue = (value, fieldname) => {
    const foundItem = this.state.Reasonlist[fieldname].find(
      (item) => item.item_value === value
    );
    return foundItem ? foundItem.item_name : null; // Return null if not found
  };
  processData = (data) => {
    const placeholderRegex = /{{.*?}}/;
    return data.map((row) =>
      row.map((value) => {
        if (placeholderRegex.test(value)) {
          console.log("val--", placeholderRegex.test(value));
          return this.checkPlaceholders(value);
        }
        return value;
      })
    );
  };
  checkPlaceholders = (value) => {
    const regex = /{{(.*?)}}/g;
    const matches = value.match(regex);
    if (matches) {
      let result = value;
      for (let match of matches) {
        const key = match
          .replace("{{", "")
          .replace("}}", "")
          .replaceAll(/"/g, "")
          .trim();
        let replacement = null;
        if (key === "branch_id") {
          replacement = parseInt(localStorage.getItem("branch_id"), 10);
        } else if (key === "effective_designation") {
          replacement = parseInt(localStorage.getItem("e_designation_id"), 10);
        } else if (key === "userid" || key === "user_id") {
          replacement = parseInt(localStorage.getItem("in_userid"), 10);
        }

        if (replacement !== null) {
          console.log("replacement", match);
          result = result.replace(match, replacement);
        }
      }
      return result;
    }
    return value; // Return original value if no placeholders are found
  };
  getNestedValue = (obj, path) => {
    console.log("path", path);
    console.log("path_obj", obj);
    return path
      .split(/[\[\]\.]+/)
      .filter(Boolean)
      .reduce((acc, part) => acc && acc[part], obj);
  };
  filterUniqueData(data) {
    console.log("filterUniqueData", data);
    const uniqueData = {};

    data.forEach((item) => {
      const segments = item.split(/[.:]/);
      let key = segments[0];

      // Check if the key exists in uniqueData
      if (!uniqueData[key]) {
        uniqueData[key] = true;
      }
    });

    // Convert uniqueData object keys to array
    return Object.keys(uniqueData);
  }
  formatQuerybuild = (query) => {
    if (!query) return {};
    const mongoQuery = {};
    if (query.combinator === "and") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$and = query.rules.map(this.formatQuerybuild);
      }
    } else if (query.combinator === "or") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$or = query.rules.map(this.formatQuerybuild);
      }
    }

    if (query.field && query.operator) {
      const field = query.field;
      const operator = query.operator;
      const queryvalue = query.value;
      let value;
      const createdAtObject = this.state.payload_config.field_mapping.find(
        (item) => item.BucketField == field
      );

      console.log("createdAtObject", queryvalue);
      if (createdAtObject) {
        if (createdAtObject.datatype == "int") {
          value = parseInt(queryvalue);
        } else if (createdAtObject.datatype == "float") {
          value = parseFloat(queryvalue);
        } else {
          value = queryvalue;
        }
      } else {
        value = queryvalue;
      }

      if (!isNaN(queryvalue) && value !== "") {
        // Check if the value is a number
        if (Number.isInteger(parseFloat(queryvalue))) {
          value = parseInt(queryvalue); // Convert to integer
        } else {
          value = parseFloat(queryvalue); // Convert to float
        }
      } else if (typeof queryvalue === "string") {
        // If the value is a string, leave it as is
        value = queryvalue;
      } else {
        // If the value doesn't match any of the above types, keep it as is
        value = queryvalue;
      }

      if (operator === "=") {
        mongoQuery[field] = value;
      } else if (operator === "!=") {
        mongoQuery[field] = { $ne: value };
      } else if (operator === ">") {
        mongoQuery[field] = { $gt: value };
      } else if (operator === "<") {
        mongoQuery[field] = { $lt: value };
      } else if (operator === ">=") {
        mongoQuery[field] = { $gte: value };
      } else if (operator === "<=") {
        mongoQuery[field] = { $lte: value };
      }

      if (operator === "exists") {
        mongoQuery[field] = {
          $exists: value.toLowerCase() == "true" ? true : false,
        };
      }
      // as per discussion with kunj sir change condition for both below. for > use $lt and $gt for <
      if (operator === "TDHG") {
        mongoQuery[field] = {
          $lt: moment()
            .subtract(value, "hours")
            .unix(),
        };
      }
      if (operator === "TDHL") {
        mongoQuery[field] = {
          $gt: moment()
            .subtract(value, "hours")
            .unix(),
        };
      }
    }
    console.log("mongoQuery-->", mongoQuery);
    return mongoQuery;
  };
  setDisabled = (value) => {
    this.setState({ disabled: value });
  };
  changedate = (date, dateString) => {
    var ts = moment(dateString, dateFormatList).unix();
    var m = moment.unix(ts);
    let inputDate = moment(date).format("YYYY-MM-DD");
    this.setState({
      filterDate: moment(inputDate).unix(),
      isDateSel: "1",
    });
  };

  update_search_filter = (field, value) => {
    console.log("field->", field);

    if (this.state.payload_config.payload_type == "M") {
      if (
        this.props.location.state.payload_config.field_mapping[0].search_query
      ) {
        const updatedRule = { ...this.state.searchconfig };

        const findRule = (rules) => {
          for (let rule of rules) {
            if (rule.field === field) {
              rule.value = value;
              return;
            }
            if (rule.rules) {
              findRule(rule.rules);
            }
          }
        };

        findRule(updatedRule.rules);

        this.setState({ searchconfig: updatedRule });
      } else {
        const { searchconfig } = this.state;
        let operator = "=";
        const existingRuleIndex = searchconfig.rules.findIndex(
          (rule) => rule.field == field
        );

        if (existingRuleIndex != -1) {
          // Update the existing rule
          searchconfig.rules[existingRuleIndex] = { field, operator, value };
        } else {
          // Insert a new rule
          searchconfig.rules.push({ field, operator, value });
        }

        // Update the state with the modified search_new object
        this.setState({ searchconfig });
      }
    }
    if (this.state.payload_config.payload_type == "B") {
      const existingIndex = this.state.searchconfig.findIndex(
        (item) => item[0] == field
      );

      if (existingIndex !== -1) {
        // If the field exists, update the corresponding value
        const updatedKeyValues = [...this.state.searchconfig];
        updatedKeyValues[existingIndex][2] = value;

        this.setState({
          searchconfig: updatedKeyValues,
        });
      } else {
        // If the key doesn't exist, create a new entry
        const newKeyValue = [field, "==", value];
        this.setState((prevState) => ({
          searchconfig: [...prevState.searchconfig, newKeyValue],
        }));
      }
    }
  };
  eventNameSearch = (e, columnName) => {
    console.log("e--", e);
    console.log("e-", columnName);

    const updatedFilters = {
      ...this.state.filters,
      [columnName]: e.target.value,
    };
    this.setState({ filters: updatedFilters });
  };
  // filterData = () => {
  //   return this.state.payload_data.filter((row) => {
  //     for (const columnName in this.state.filters) {
  //       const filterText = this.state.filters[columnName].toLowerCase();
  //       const cellText = String(row[columnName]).toLowerCase();
  //       if (!cellText.includes(filterText)) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   });
  // };

  filterData = () => {
    const { payload_data, filters } = this.state;

    // Assuming `filters` and `this.state.payload_config.field_mapping` are defined elsewhere
    console.log("payload_data--->", payload_data);
    console.log("payload_filters--->", filters);
    const filteredData = payload_data.filter((row) => {
      if (!row) {
        return false;
      }

      if (filters) {
        return Object.keys(filters).every((columnName) => {
          const filterValues = filters[columnName];
          const config = this.state.payload_config.field_mapping.find(
            (config) => config.BucketField === columnName
          );

          if (!config) {
            return false; // Exit early if configuration for column not found
          }

          const cellText = this.deepGet(row, columnName);

          if (Array.isArray(filterValues)) {
            // Check if any filter value matches the cell text
            return filterValues.some((filterText) => {
              if (typeof cellText === "number") {
                console.log("cellText", cellText);

                const cellContainsFilter = new RegExp(
                  `\\b${cellText}\\b`,
                  "i"
                ).test(filterText);
                return cellContainsFilter;
              } else {
                return String(cellText)
                  .toLowerCase()
                  .includes(filterText.toLowerCase());
              }
            });
          } else {
            // Handle single filter value as before
            const filterText = filterValues.toLowerCase();
            if (typeof cellText === "number" && filterText != "") {
              const cellContainsFilter = new RegExp(
                `\\b${cellText}\\b`,
                "i"
              ).test(filterText);
              return cellContainsFilter;
            } else {
              return String(cellText)
                .toLowerCase()
                .includes(filterText);
            }
          }
        });
      }
    });

    const filterdata = {};

    this.state.payload_config.field_mapping.forEach((item) => {
      if (item.ExcludeValueflag === "1" && item.ExcludeValue !== "") {
        filterdata[item.BucketField] = item.ExcludeValue;
      }
    });
    const parsedFilter = {};
    for (const key in filterdata) {
      parsedFilter[key] = filterdata[key]
        .split(",")
        .map((value) => value.trim());
    }

    // Function to check if an item should be excluded
    const shouldExclude = (item) => {
      for (const key in parsedFilter) {
        if (parsedFilter[key].includes(String(item[key]))) {
          return true;
        }
      }
      return false;
    };

    // Filter the data
    const filtered_record = filteredData.filter((item) => !shouldExclude(item));

    return filtered_record;
  };

  deepGet = (obj, path) => {
    const keys = path.split(".");
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
  };

  converttoarray = (key, data) => {
    const keys = key.split(/[.:]/);

    // Initialize a variable to store the current data object
    let currentData = data;

    // Loop through each part of the key
    for (const k of keys) {
      console.log("key->", k);
      // Check if the currentData is an object and has the key
      if (currentData && currentData.hasOwnProperty(k)) {
        currentData = currentData[k]; // Update currentData to the nested object
      } else {
        return ""; // Return a default value if any part of the key is not present
      }
    }
    return currentData;
  };
  showpopup = () => {
    this.setState({
      isSettings: true,
    });
  };
  updateFilter = () => {
    let payload = {};
    payload.view_config = this.state.updatecheckbox;
    payload.search_config = this.state.updatesearchconfig;
    payload.filtername = this.state.updatefiltername;
    //const jsonObject = JSON.stringify(payload);
    console.log("Updatedfilter", JSON.stringify(payload));
    this.setState(
      {
        updated_filterdata: payload,
      },
      () => this.editFilter()
    );
  };
  savepreferences = async () => {
    try {
      const url = process.env.REACT_APP_URL + "/add/callbacks/store_user_view";
      let payload = {};
      payload.user_id = localStorage.getItem("in_userid");
      payload.view_id = this.state.payload_config.view_id;
      payload.view_config = this.state.checkedCheckboxes;
      payload.search_config = this.state.searchconfig;
      payload.filtername = this.state.filtername;
      const jsonObject = [payload];

      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: JSON.stringify(jsonObject),
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.setState({ searchconfig: this.state.defaultsearchconfig });
      //this.getPayloads();
      this.getviewcolconfig();
    }
  };

  updateUniqueData = () => {
    const uniqueBucketFields = new Set();

    // Filter the data array to keep only unique items
    const uniqueData =
      this.state.massUpdateData &&
      this.state.massUpdateData.filter((item) => {
        const isUnique = !uniqueBucketFields.has(item.BucketField);

        // If the BucketField is unique, add it to the Set
        if (isUnique) {
          uniqueBucketFields.add(item.BucketField);
        }
        return isUnique;
      });
    if (uniqueData.some((item) => item.datatype === "selectoption")) {
      const selctdata = uniqueData.filter(
        (item) => item.datatype === "selectoption"
      );
      // this.setState({
      //   selectoption:
      // })
      console.log("selctdataselctdata", selctdata);
      this.selectoptiondata(selctdata);
    }
    if (uniqueData.some((item) => item.datatype === "dropdownlist")) {
      // console.log(
      //   "isUnique",
      //   uniqueData.some((item) => item.datatype === "dropdownlist")
      // );
      const formassupdate = 1;
      let massupdate = this.state.payload_data;
      console.log("payload_data", massupdate);
      if (massupdate.length > 0) {
        this.getfirstrowstatus(massupdate);
      }
    }
    this.setState({
      updateUniqueData: uniqueData,
    });
    return uniqueData;
    // Update the state with unique data
    // this.setState({
    //   massUpdateData: uniqueData,
    // });
  };

  selectoptiondata = async (getitem) => {
    const getapi = this.state.payload_config.get_api_mapping.filter(
      (item) => item.action_name == getitem[0].selectoption
    );
    let massupdate = this.state.payload_data[0];
    console.log("itemitem", getapi);
    let cnt = 1;
    if (massupdate && getapi) {
      const url = this.getDataBetweenBraces(getapi[0].api_url, massupdate);
      console.log("urlurl", url);
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "payloadview",
          user_id: localStorage.getItem("in_userid"),
        },
      };
      if (!this.state[getitem[0].BucketField]) {
        const response = await customFetch(
          process.env.REACT_APP_URL + "/" + url,
          requestOptions
        );

        const data = await response.json();
        if (data.length > 0) {
          const respinsedata = data.map((item) =>
            this.getDataFronresponse(getapi[0].getoutputdata, item)
          );

          console.log("respinsedata", respinsedata);
          const transformedData = respinsedata.map((item) => ({
            key: item[0].key,
            value: item[1].key,
          }));
          this.setState(
            {
              [getitem[0].BucketField]: transformedData,
            },
            () => console.log("datadata-", this.state[getitem[0].BucketField])
          );
          // console.log(
          //   "jsonjson",
          //   data.map((item) =>
          //     this.getDataFronresponse(getapi[0].getoutputdata, item)
          //   )
          // );
        }
      }
      cnt++;
    }
  };
  getDataFronresponse = (template, data) => {
    const regex = /{{(.*?)}}/g;
    let match;
    const result = [];

    while ((match = regex.exec(template)) !== null) {
      const key = match[1].trim();
      result.push({ key: data[key], value: data[key] });
    }

    return result;
  };

  getDataBetweenBraces = (template, data) => {
    if (template && data) {
      return template.replace(/{{(.*?)}}/g, (_, key) => {
        const trimmedKey = key.trim();
        if (trimmedKey === "user_id") {
          return localStorage.getItem("in_userid");
        }
        if (trimmedKey === "branch_id") {
          return localStorage.getItem("branch_id");
        }
        if (
          trimmedKey === "effective_designation_id" ||
          trimmedKey === "effective_designation"
        ) {
          return localStorage.getItem("e_designation_id");
        }
        return this.getValue(data, trimmedKey);
      });
    }
  };

  getValue = (data, path) => {
    const keys = path.split(".");
    let value = data;
    for (const key of keys) {
      if (value === undefined || value === null) {
        return ""; // Return empty string if value is undefined or null
      }
      // Check if key contains array notation, e.g., "cash_transfer_log[0]"
      if (key.includes("[")) {
        const [arrayKey, index] = key.split(/[[\]]/).filter(Boolean);
        value = value[arrayKey];
        if (!value) return ""; // Early return if array key is not found
        value = value[Number(index)];
      } else {
        value = value[key];
      }
    }
    return value !== undefined ? value : "";
  };
  combineUniqueNextPossibleValues = (data) => {
    const combinedResult = {
      next_possible_values: new Set(),
      next_possible_values_path: new Set(),
    };
    console.log("combinedResult", combinedResult);

    data.forEach((item) => {
      if (item.next_possible_values) {
        item.next_possible_values.forEach((value) =>
          combinedResult.next_possible_values.add(value)
        );
      }
      if (item.next_possible_values_path) {
        item.next_possible_values_path.forEach((path) =>
          combinedResult.next_possible_values_path.add(path)
        );
      }
    });

    return [
      {
        next_possible_values: Array.from(combinedResult.next_possible_values),
        next_possible_values_path: Array.from(
          combinedResult.next_possible_values_path
        ),
      },
    ];
  };
  getfirstrowstatus = async (massupdate) => {
    const filteredList = this.state.payload_config.field_mapping.filter(
      (item) => {
        const result =
          item.datatype === "dropdownlist" &&
          item.dropdowncondition &&
          item.dropdowncondition.length > 0;
        return result;
      }
    );
    this.setState({ dropdowncondition: filteredList });
    console.log("--Reasonlist", filteredList);
    //console.log("--Reasonlist", filteredList);

    let dropdown_id = "";
    let item_value = "";
    let fieldname = "";
    if (filteredList.length > 0) {
      fieldname = filteredList[0].BucketField;
      // item_value = massupdate[0][fieldname];
      dropdown_id = filteredList[0].dropdownlist;
    }
    if (dropdown_id) {
      const getuniquedata = [
        ...new Set(massupdate.map((item) => item[fieldname])),
      ];
      console.log("getuniquedata", getuniquedata);
      let generate_Id = generateId(10);
      if (this.state.isGetFirstdata == 0) {
        const requestOptions = {
          method: "GET",
          headers: {
            "api-token": this.state.api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "payloadview",
            user_id: localStorage.getItem("in_userid"),
          },
        };

        try {
          // Make all API calls and combine their results
          const Massresults = await Promise.all(
            getuniquedata.map(async (data) =>
              this.callApiForStatus(dropdown_id, data, requestOptions)
            )
          );
          // Filter out null results and combine the responses
          const combinedResults = Massresults.filter(
            (result) => result !== null
          ).reduce((acc, current) => {
            return acc.concat(current);
          }, []);

          const ReasonlistObject = {
            [fieldname]: combinedResults,
          };
          this.setState(
            {
              Reasonlist_default: ReasonlistObject,
            },
            () =>
              console.log("ReasonlistObject:", this.state.Reasonlist_default)
          );
          console.log("filteredResults:", combinedResults);
          let finalResults = this.combineUniqueNextPossibleValues(
            combinedResults
          );
          console.log("NextPossibleValues", finalResults);

          // Your existing code for processing the combined and filtered results
          finalResults.forEach((json) => {
            let resultObject = "";
            if (json.next_possible_values) {
              const filteredArray =
                this.state.MassReasonlist &&
                this.state.MassReasonlist[fieldname].filter((item) =>
                  json.next_possible_values.includes(item.item_value)
                );

              resultObject = {
                [fieldname]: filteredArray,
              };
              console.log("filteredArray", filteredArray);
            } else {
              resultObject = {
                [fieldname]: "",
              };
            }

            const next_possible_values = json.next_possible_values;
            let next_possible_values_path = json.next_possible_values_path;
            console.log("next_possible_values--", next_possible_values);
            console.log(
              "next_possible_values_path--",
              next_possible_values_path
            );

            if (next_possible_values_path) {
              if (!Array.isArray(next_possible_values_path)) {
                try {
                  // Try to parse the data as JSON
                  next_possible_values_path = JSON.parse(
                    next_possible_values_path
                  );

                  // Check again if the parsed data is an array
                  if (!Array.isArray(next_possible_values_path)) {
                    throw new Error("Data is not an array");
                  }
                } catch (error) {
                  console.error("Error parsing data as JSON:", error);
                }
              }

              console.log("resultObject", resultObject[fieldname]);
              console.log("resultObject_", resultObject);
              let finaldropdownResults = resultObject;
              if (
                filteredList[0].dropdowncondition &&
                filteredList[0].dropdowncondition.length > 0
              ) {
                finaldropdownResults[fieldname] = resultObject[
                  fieldname
                ].filter((item) => {
                  return filteredList[0].dropdowncondition.some((condition) => {
                    return condition.filtereddropdownlist === item.item_value;
                  });
                });
                // resultObject[fieldname].forEach((status) => {
                //   const index = next_possible_values.indexOf(status.item_value);
                //   if (index !== -1) {
                //     status.item_name = next_possible_values_path[index];
                //   }
                // });
                const updatedStatuses = resultObject[fieldname].map(
                  (status) => {
                    const index = next_possible_values.indexOf(
                      status.item_value
                    );
                    if (index !== -1) {
                      return {
                        ...status,
                        item_name: next_possible_values_path[index],
                      };
                    }
                    return status;
                  }
                );

                finaldropdownResults[fieldname] = updatedStatuses;
              } else {
                finaldropdownResults = resultObject;
                console.log("finalResults_else", finaldropdownResults);
              }
              console.log("finalResults_if", finaldropdownResults);
              this.setState({
                MassReasonlist: finaldropdownResults,
                isGetFirstdata: 1,
              });
            } else {
              this.setState({
                MassReasonlist: resultObject,
                isGetFirstdata: 1,
              });
            }
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };
  findCommonElements = (array1, array2) => {
    return array1.filter((element) => array2.includes(element));
  };
  callApiForStatus = async (dropdown_id, item_value, requestOptions) => {
    try {
      const res = await customFetch(
        `${process.env.REACT_APP_URL}/get/dropdown/item/master/data/${dropdown_id}/${item_value}`,
        requestOptions
      );
      return await res.json();
    } catch (error) {
      console.error(`Failed to fetch status ${item_value}`, error);
      return null;
    }
  };

  handlemassInputChange = (index, key, value) => {
    this.setState((prevState) => {
      const newData = [...prevState.massUpdateInput];
      if (!newData[index]) {
        // If the array element doesn't exist yet, create it
        newData[index] = { my_key: key, value: "" };
      }
      newData[index].my_key = key;
      newData[index].value = value; // Set value to e.target.value
      for (let i = 0; i < newData.length; i++) {
        if (newData[i] === null || !newData[i]) {
          newData[i] = { my_key: "", value: "" };
        }
      }
      console.log("uniqueData", newData);
      return {
        massUpdateInput: newData,
      };
    });
    const findconfig = this.state.payload_config.field_mapping.filter(
      (item) => item.BucketField === key
    );
    findconfig.forEach((conf_data) => {
      console.log("conf_data", conf_data);

      if (conf_data.datatype == "dropdownlist" && value != NaN) {
        this.setState({ filters: {} });
        const current_data = this.state.Reasonlist[key].filter((item) => {
          if (item.next_possible_values) {
            const possibleValues = item.next_possible_values;
            return possibleValues.includes(value);
          }
          return false;
        });
        const filtername = this.state.MassReasonlist[key].filter(
          (item) => item.item_value == value
        );
        console.log("f_data", current_data);
        if (filtername.length > 0) {
          this.setState({
            filter_name: filtername.length > 0 ? filtername[0].item_name : "",
          });
        } else {
          this.setState({ filter_name: "" });
        }
        if (current_data.length > 1) {
          // this.setState({
          //   filters: {},
          //   filtered_cnt: this.state.payload_data.length,
          // });
          // current_data.map((cur_data) => {
          //   const updatedFilters = {
          //     ...this.state.filters,
          //     [key]: Array.isArray(this.state.filters[key])
          //       ? [...this.state.filters[key], cur_data.item_value !== null ? cur_data.item_value.toString() : undefined]
          //       : [cur_data.item_value !== null ? cur_data.item_value.toString() : undefined],
          //   };

          //   this.setState({ filters: updatedFilters }, () => {
          //     console.log("updatedFilters", updatedFilters);
          //     this.getfiltercnt();
          //   });
          // });
          const filters = current_data.reduce((acc, item) => {
            acc[key] = (acc[key] || []).concat(item.item_value);
            return acc;
          }, {});
          this.setState({ filters: filters }, () => {
            console.log("updatedFilters", filters);
            this.getfiltercnt(value);
          });
        } else {
          current_data.map((cur_data) => {
            const updatedFilters = {
              ...this.state.filters,
              [key]: cur_data.item_value.toString(),
            };
            console.log("updatedFilters", updatedFilters);

            this.setState(
              {
                filters: updatedFilters,
              },
              () => this.getfiltercnt(value)
            );
          });
        }
      }
    });
  };
  getfiltercnt = (value) => {
    const filtercnt = this.filterData();
    const dropdownCondition = this.state.dropdowncondition[0].dropdowncondition.filter(
      (item) => item.filtereddropdownlist == value
    );
    console.log("dropdowncondition--", dropdownCondition);

    this.setState(
      {
        filtered_cnt: filtercnt.length,
        dropdoenFiltered_data: filtercnt,
        filtered_condition: dropdownCondition,
      },
      () => this.getconfigsum()
    );
  };
  checkanduploadfile = () => {
    return new Promise((resolve, reject) => {
      const filteredList = this.state.payload_config.field_mapping.filter(
        (item) => item.datatype === "Uploadfile"
      );
      if (filteredList.length === 0) {
        resolve(false); // No file to upload, resolve with false
        return;
      }

      const file = this.state[filteredList[0].BucketField];
      if (file) {
        const fileExtension = file.name.split(".").pop();
        let filename = filteredList[0].UploadName
          ? `${filteredList[0].UploadName}.${fileExtension}`
          : file.name;
        const filepath = `${filteredList[0].UploadPath}/${filename}`;

        const formdata = new FormData();
        formdata.append("file", file);
        formdata.append(
          "blob_url",
          `deposit_slips_by_transaction_officer/${file.name}`
        );

        const requestOptions = {
          method: "POST",
          headers: {
            "api-token": this.state.api_token,
          },
          body: formdata,
        };

        customFetch(
          process.env.REACT_APP_URL + "/save/files/bucket",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            const newItem = {
              my_key: filteredList[0].BucketField,
              value: data[0].blob_url,
            };
            const newData = [...this.state.massUpdateInput, newItem];

            this.setState({ isSpin: false, massUpdateInput: newData }, () => {
              console.log("massUpdateInput", this.state.massUpdateInput);
              resolve(true); // Resolve with true to indicate state update
            });
          })
          .catch((error) => {
            console.log("error", error);
            reject(error);
          });
      } else {
        resolve(false); // Resolve with false if no file to upload
      }
    });
  };

  updatemassdata = async () => {
    // if (this.state.hasFile == 1) {
    //   const checkanduploadfile = await this.checkanduploadfile();
    // }
    let txn_no = "NDD" + generateId(7) + "_" + generateId(5);
    let isFileUploaded = false;

    if (this.state.hasFile === 1) {
      try {
        isFileUploaded = await this.checkanduploadfile();
      } catch (error) {
        console.error("File upload failed:", error);
        return;
      }
    } else {
      isFileUploaded = true;
    }
    if (isFileUploaded) {
      const filteredData = this.filterData();
      const usethisfield = this.state.payload_config.field_mapping
        .filter((field) => field.useasinput === "1")
        .map((field) => field.BucketField);
      var filtered_Data = [];
      var targetIds = this.state.selectedMassUpdateData;
      if (
        this.state.payload_config.payload_type == "M" &&
        this.state.selectedMassUpdateData
      ) {
        filtered_Data =
          filteredData &&
          filteredData.filter(function(item) {
            return item._id && targetIds.includes(item._id.$oid);
          });
        console.log("filtered_Data if", filtered_Data);
      } else if (usethisfield) {
        filtered_Data =
          filteredData &&
          filteredData.filter(function(item) {
            console.log("filteredData.filter", item);
            return item[usethisfield] && targetIds.includes(item[usethisfield]);
          });
        console.log("filtered_Data else", filtered_Data);
      }

      const autogeneratetxn = this.state.payload_config.field_mapping.filter(
        (item) => item.autogeneratetxn === "1"
      );
      console.log("massUpdateInput-->", autogeneratetxn);

      const objectsEqual = (obj1, obj2) =>
        JSON.stringify(obj1) === JSON.stringify(obj2);

      // Store the changed data
      const changedData = [];

      // Update data based on matching key in data1 and track changes
      console.log("this.state.massUpdateInput", this.state.massUpdateInput);
      const filteredmassUpdateInput = this.state.massUpdateInput.filter(
        (item) => item !== null && item !== undefined
      );

      filteredmassUpdateInput.forEach((item) => {
        if (item && item.my_key !== undefined && item.value !== undefined) {
          filtered_Data.forEach((filteredItem) => {
            let changedItem;
            if (autogeneratetxn.length > 0) {
              let txn_ref_no = filteredItem[autogeneratetxn[0].BucketField]
                ? `${filteredItem[autogeneratetxn[0].BucketField]}|${txn_no}`
                : txn_no;

              if (
                filteredItem &&
                filteredItem[item.my_key] !== undefined &&
                filteredItem[item.my_key] !== item.value
              ) {
                changedItem = {
                  object_id: filteredItem._id.$oid,
                  [autogeneratetxn[0].BucketField]: txn_ref_no,
                  [item.my_key]: filteredItem[item.my_key],
                  [item.my_key]: item.value,
                };
              } else {
                changedItem = {
                  object_id: filteredItem._id.$oid,
                  [autogeneratetxn[0].BucketField]: txn_ref_no,
                  [item.my_key]: item.my_key,
                  [item.my_key]: item.value,
                };
              }
            } else {
              if (
                filteredItem &&
                filteredItem[item.my_key] !== undefined &&
                filteredItem[item.my_key] !== item.value
              ) {
                changedItem = {
                  object_id: filteredItem[usethisfield]
                    ? filteredItem[usethisfield]
                    : filteredItem._id.$oid,
                  [item.my_key]: filteredItem[item.my_key],
                  [item.my_key]: item.value,
                };
              } else {
                changedItem = {
                  object_id: filteredItem[usethisfield]
                    ? filteredItem[usethisfield]
                    : filteredItem._id.$oid,
                  [item.my_key]: item.my_key,
                  [item.my_key]: item.value,
                };
              }
            }
            if (
              !changedData.some((changed) => objectsEqual(changed, changedItem))
            ) {
              changedData.push(changedItem);
            }
          });
        }
      });

      console.log("changedItem", changedData);
      console.log("payload_config", this.state.payload_config);
      this.setState({ DataForMassUpdate: filtered_Data });
      //this.update_mass_data(filtered_Data);
      let confirmValues = [];

      for (const dataItem of changedData) {
        for (const configItem of this.state.payload_config.field_mapping) {
          const {
            BucketField,
            isConfirm,
            mass_condition_field,
            mass_condition_value,
            showcondition,
          } = configItem;

          if (dataItem[BucketField] !== undefined && isConfirm === "1") {
            console.log("confirmValues.length", dataItem[BucketField]);
            confirmValues.push({
              [BucketField]: dataItem[BucketField],
              confirm: "your_confirm_value_here", // Add your confirm value here
            });
            // Exit the loop if isConfirm is 1
            break;
          }

          // if (
          //   showcondition == 1 &&
          //   mass_condition_value
          //     .split(",")
          //     .includes(
          //       this.state.massUpdateInput.length > 0 &&
          //         this.state.massUpdateInput
          //           .find((item) => item.my_key == mass_condition_field)
          //           .value.toString()
          //     )
          // ) {
          //   console.log("configItem", this.state.massUpdateInput);

          // }
          // if (configItem.massrequire == 1) {
          //   //BucketField
          //   if (
          //     this.state.massUpdateInput.some(
          //       (item) => item.my_key === configItem.BucketField
          //     ) == false
          //   ) {
          //     this.setState({
          //       isMassupdclick: false,
          //     });
          //     alert(`Please enter value for ${configItem.fieldname}`);
          //     return;
          //   }
          // }
          if (configItem.massrequire === "1") {
            console.log("configItem", configItem);
            // Check if mass_condition_field exists in massUpdateInput
            // let massConditionFieldValue = this.state.massUpdateInput.find(
            //   (item) => item.my_key === configItem.mass_condition_field
            // );

            let massConditionFieldValue = this.state.massUpdateInput.find(
              (item) =>
                item.my_key === configItem.mass_condition_field &&
                mass_condition_value.includes(item.value)
            );

            if (massConditionFieldValue) {
              // Get the corresponding BucketField
              let bucketField = configItem.BucketField;
              let fieldname = configItem.fieldname;

              // Check if BucketField exists in massUpdateInput
              let bucketFieldItem = this.state.massUpdateInput.find(
                (item) => item.my_key === bucketField
              );
              console.log("massConditionFieldValue", bucketFieldItem);
              if (bucketFieldItem) {
                // Check if the value exists and is not empty
                if (bucketFieldItem.value) {
                  console.log(`BucketField '${bucketField}' has a value.`);
                } else {
                  console.log(
                    `BucketField '${bucketField +
                      fieldname}' exists but has no value.`
                  );
                  this.setState({
                    isMassupdclick: false,
                  });
                  alert(`Please enter value for ${fieldname}`);
                  return;
                }
              } else {
                console.log(
                  `BucketField '${bucketField +
                    fieldname}' does not exist in massUpdateInput.`
                );
                this.setState({
                  isMassupdclick: false,
                });
                alert(`Please enter value for ${fieldname}`);
                return;
              }
            } else {
              if (configItem.mass_condition_field) {
                console.log(
                  `mass_condition_field '${configItem.BucketField}' does not exist in massUpdateInput.`
                );
              } else {
                console.log(
                  `mass_condition_field '${configItem.BucketField}' does not exist in massUpdateInput else.`
                );

                if (
                  this.state.massUpdateInput.some(
                    (item) => item.my_key === configItem.BucketField
                  ) == false
                ) {
                  this.setState({
                    isMassupdclick: false,
                  });
                  alert(`Please enter value for ${configItem.fieldname}`);
                  return;
                }
              }
            }
          }
        }
      }

      // check for condition
      let isvalid = true;
      if (this.state.filtered_condition) {
        if (this.state.filtered_condition[0].filteredCondition) {
          if (this.state.filtered_condition[0].filteredCondition == "1") {
            if (filteredData.length != changedData.length) {
              isvalid = false;
              this.setState({ isMassupdclick: false });
            }
          }
        }

        if (
          this.state.filtered_condition[0].massValidationKey &&
          this.state.filtered_condition[0].massValidationvalue
        ) {
          const uniqueStatuses = Array.from(
            new Set(
              this.state.payload_data.map(
                (item) =>
                  item[this.state.filtered_condition[0].massValidationKey]
              )
            )
          );
          if (
            uniqueStatuses.some((value) =>
              this.state.filtered_condition[0].massValidationvalue.includes(
                value
              )
            )
          ) {
            isvalid = false;
            console.log("uniqueStatuses", isvalid);
          }
        }
      }
      if (isvalid) {
        const updatemassapi = this.state.payload_config.action_mapping.filter(
          (item) => item.action_event === "massupdate"
        );
        const combinedata = this.mergeDataByObjectId(changedData);
        console.log("combinedata", combinedata);

        if (confirmValues.length > 0) {
          const isConfirmed = window.confirm(
            "Are you sure you want to update the value?"
          );
          if (isConfirmed) {
            if (updatemassapi.length > 0) {
              this.updatemassapi(changedData, updatemassapi);
            } else {
              combinedata.map((massdata, index) => {
                let object_id = massdata["object_id"];
                delete massdata["object_id"];
                console.log("del oid", massdata);

                this.update_mass_data(
                  object_id,
                  massdata,
                  combinedata.length,
                  index
                );
              });
            }
          }
        } else {
          if (updatemassapi.length > 0) {
            this.updatemassapi(changedData, updatemassapi);
          } else {
            combinedata.map((massdata, index) => {
              let object_id = massdata["object_id"];
              delete massdata["object_id"];
              console.log("del oid", massdata);

              this.update_mass_data(
                object_id,
                massdata,
                combinedata.length,
                index
              );
            });
          }
        }
      } else {
        alert("You are not allowed to perform this action");
      }
    }
  };
  mergeDataByObjectId = (data) => {
    return Object.values(
      data.reduce((acc, item) => {
        if (!acc[item.object_id]) {
          acc[item.object_id] = { object_id: item.object_id };
        }
        Object.assign(acc[item.object_id], item);
        return acc;
      }, {})
    );
  };
  updatemassapi = async (data, apiconfig) => {
    console.log("updatemassapi", data);
    console.log("updatemassapi_apiconfig", apiconfig);
    this.setState({
      isspin: true,
    });
    const result = {};

    data.forEach((item) => {
      const { object_id, ...rest } = item;
      if (!result["all"]) {
        result["all"] = { object_id: [] };
      }
      if (!result["all"].object_id.includes(object_id)) {
        result["all"].object_id.push(object_id);
      }
      for (const [key, value] of Object.entries(rest)) {
        result["all"][key] = value;
      }
    });
    const payload_configdata = this.state.payload_config;
    const previousOutput = Object.values(result);
    console.log("previousOutput", previousOutput);
    const updatedFormData = this.createNewFormData(
      payload_configdata,
      previousOutput
    );
    console.log("updatedFormData", updatedFormData);
    if (updatedFormData) {
      const form = new FormData();

      updatedFormData.forEach((field) => {
        form.append(
          field.formdatakey,
          this.checkinputtype(field) //field.formdatavalue ? field.formdatavalue : field.defaultvalue
        );
      });
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": this.state.api_token,
        },
        body: form,
      };
      let url;
      if (apiconfig.length > 0 && apiconfig[0].url_type == "N") {
        url = `${process.env.REACT_APP_URL}/${apiconfig[0].api_url}`;
      } else {
        url = apiconfig[0].api_url;
      }

      const response = await customFetch(url, requestOptions);
      const responsedata = await response.json();
      console.log("responsedataresponsedata", responsedata);
      //alert(responsedata.message);
      this.setState({
        isspin: false,
        isError: true,
        isErrorMessage: responsedata.message,
      });
    }
  };
  checkinputtype = (data) => {
    if (data.formdatavalue) {
      if (data.inputtype == "datetime") {
        return moment(data.formdatavalue).format("YYYY-MM-DD HH:MM:ss");
      } else if (data.inputtype == "json") {
        return JSON.stringify(data.formdatavalue);
      } else {
        console.log("checkinputtype", data.formdatavalue);
        return data.formdatavalue;
      }
    } else {
      return data.defaultvalue;
    }
  };
  createNewFormData(data, previousOutput) {
    console.log("updatedFormData_1", data);
    if (!data || !data.action_mapping || !previousOutput) {
      throw new Error("Invalid input data or previous output.");
    }

    const actionMapping = data.action_mapping.find(
      (action) => action.action_event === "massupdate"
    );

    if (!actionMapping) {
      return [];
    }

    const newFormData = actionMapping.formdata.map((item) => {
      let value;

      // Check if value exists in previousOutput
      const previousData = previousOutput[0];
      if (previousData.hasOwnProperty(item.formdatakey)) {
        value = previousData[item.formdatakey];
      }

      // If the value is still not found, check field_mapping
      if (value === undefined) {
        const fieldMapping = data.field_mapping.find(
          (field) => field.fieldname == item.formdatavalue
        );
        if (fieldMapping) {
          if (fieldMapping.useasinput == "1") {
            console.log("fieldMapping.BucketField", item);
            value = previousData.object_id; // Use object_id from previousOutput
          } else if (fieldMapping.fieldname === item.formdatavalue) {
            value = previousData[fieldMapping.BucketField]; // Use user_refer from previousOutput
          }
        }
        // if (fieldMapping && fieldMapping.useasinput === "1") {
        //     value = previousData.object_id;  // Pick object_id from previousOutput
        // } else {
        //     value = item.formdatavalue;
        // }

        // Check if defaultvalue has placeholders and replace with corresponding value from previousOutput
        if (item.defaultvalue && item.defaultvalue.includes("{{")) {
          value = this.checkPlaceholders(item.defaultvalue, previousData);
        }
      }

      return {
        ...item,
        formdatavalue: value || item.formdatavalue,
      };
    });

    return newFormData;
  }

  update_mass_data = async (
    objectid,
    data,
    allchangedData,
    changedDataIndex
  ) => {
    console.log("objectid", objectid);
    console.log("data", data);
    this.setState({ isspin: true });
    if (this.state.payload_config.payload_type == "M" && objectid && data) {
      let dbname, collectionname;
      if (this.state.payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = this.state.payload_config.db_name;
      }

      if (this.state.payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = this.state.payload_config.collection_name;
      }
      console.log("Data1111->", data);
      if (
        this.state.payload_config.setupdated_at &&
        this.state.payload_config.setupdated_at
      ) {
        data = this.addupdateddata(data);
      }
      const bucketFields = this.state.payload_config.field_mapping.map(
        (item) => item.BucketField
      );
      const addDataBeforeField = this.state.payload_config.field_mapping.find(
        (item) => item.add_data_before === "1"
      );

      // Initialize the result object
      const result = { add_data_before: {} };

      // Flag to check if any key matched
      let anyKeyMatched = false;

      // Iterate over data keys
      for (const key in data) {
        if (bucketFields.includes(key)) {
          if (addDataBeforeField && key === addDataBeforeField.BucketField) {
            result["add_data_before"][key] = data[key];
            anyKeyMatched = true;
          } else {
            result[key] = data[key];
          }
        } else {
          result[key] = data[key];
        }
      }

      // If no keys matched, ensure add_data_before is an empty object
      if (!anyKeyMatched) {
        result["add_data_before"] = {};
      }
      let response = {};
      if (this.state.payload_config.view_type == "AV") {
        let committype;
        if (this.state.payload_config.commit_type) {
          committype = this.state.payload_config.commit_type;
        } else {
          committype = "1";
        }
        const allResponses = this.getGroupForEntry(result,"SubmitData");
        const updateasresultData = allResponses.map((entry) => {
          return {
            ...result,
            object_id: entry._id.$oid,
          };
        });
        const updatedData = updateasresultData.map((item) => {
          return {
            ...item, // Spread the existing properties of the item
            commit_flag: committype, // Add the new property commit with value 1
          };
        });
        console.log("allResponses", updatedData);
        response = await update_mongogrouppayload(
          this.state.api_token,
          dbname,
          collectionname,
          updatedData
        );
      } else {
        response = await update_mongopayload(
          this.state.api_token,
          dbname,
          collectionname,
          objectid,
          result
        );
      }
      console.log("alldata", allchangedData + "==" + changedDataIndex);
      if (allchangedData - 1 == changedDataIndex) {
        if (response.status == 1) {
          this.setState({ isspin: false });
          Modal.success({
            content: <pre id="json">Payload updated successfully!</pre>,
            title: "Success",
            onOk: () => window.location.reload(),
          });
          return true;
        } else {
          this.setState({ isspin: false });
          Modal.error({
            content: <pre id="json">{response.message}</pre>,
            title: "Error",
          });
          return false;
        }
      }
    }
    this.setState({
      massUpdateInput: [],
    });
    this.getPayloads();
  };
  addupdateddata = (data) => {
    const now = moment();
    const unixTimestamp = now.unix();
    let updateddata = {
      ...data,
      updated_at: unixTimestamp,
      updated_by: parseInt(localStorage.getItem("in_userid")),
    };
    return updateddata;
  };
  handleClick = () => {
    this.setState({ isExporting: true }, () => {
      document.getElementById("table-xls-button").click();
      document
        .getElementById("table-xls-button")
        .addEventListener("click", this.handleStateUpdate());
    });
  };
  handleStateUpdate = () => {
    // Update state
    // This will be executed after the export is completed
    this.setState({ isExporting: false });
  };
  storegroupid = (groupid, index, bucket_field) => {
    console.log("group_id", groupid);
    let generate_Id = generateId(10);
    this.setState({ group_id: groupid });
    const form = new FormData();
    form.append("group_id", groupid);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": this.state.api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "",
        user_id: localStorage.getItem("in_userid"),
      },
      body: form,
    };
    const response = customFetch(
      process.env.REACT_APP_URL + "/partner/add/groupid",
      requestOptions
    );
    // const newgroupid = [...updategroupid, { group_id: groupid }];
    // setupdategroupid(newgroupid);

    // Update the state with the new array

    console.log(response);

    Modal.success({
      content: <pre id="json">{JSON.stringify(response.message)}</pre>,
      title: "Success",
    });
    this.handlemassInputChange(index, bucket_field, groupid);
    // customFetch(process.env.REACT_APP_URL + "/add/groupid", requestOptions)
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((json) => {
    //     setImageView(json.public_url);
    //     setisImageView(true);
    //   });
  };

  adduniqueid = (index, bucket_field) => {
    let maxNumericValue = Number.NEGATIVE_INFINITY;
    let maxGroupID = null;
    this.state.unique_groups &&
      this.state.unique_groups.forEach((item) => {
        if (item.group_id.includes(this.state.supervisor_branch_code)) {
          const numericValue = parseInt(
            item.group_id.replace(this.state.supervisor_branch_code + "G", ""),
            10
          );
          if (numericValue > maxNumericValue) {
            maxNumericValue = numericValue;
            maxGroupID = item.group_id;
          }
        }
      });
    let maxValue;
    if (maxGroupID != null) {
      maxValue = maxGroupID;
    } else {
      maxValue = 0;
    }
    console.log("maxValue", this.state.supervisor_branch_code);
    let newUniqueID;
    if (maxValue == 0) {
      newUniqueID = this.state.supervisor_branch_code + "G000001";
    } else {
      let splitid = maxValue.split("G");
      const numericPart = parseInt(splitid[1], 10) + 1;

      // Construct the new group_id
      newUniqueID = splitid[0] + "G" + numericPart.toString().padStart(6, "0");
    }
    console.log("newUniqueID", newUniqueID);

    // setgroupid(newUniqueID);
    // console.log("groupid", groupid);
    this.storegroupid(newUniqueID, index, bucket_field);
  };
  CustomValueEditor = (props) => {
    if (props.fieldData.datatype === "date") {
      return (
        <div>
          <DatePicker
            dateFormat={dateFormatList}
            onChange={(date) => {
              // Update the query with the selected date
              var ts = moment(date, dateFormatList).unix();
              //var m = moment.unix(ts);
              // const updatedQuery = {
              //   ...props.value,
              //   [props.fieldData.field]: ts,
              // };
              props.handleOnChange(ts);
            }}
          />
        </div>
      );
    }
    return <ValueEditor {...props} />;
  };

  SearchDropdownlist = (val) => {
    console.log("valll--0", val);
    let dropdown_data = "";
    if (val.bucketfield.dropDownOptions) {
      dropdown_data = val.bucketfield.dropDownOptions;
    }
    let bucket_field = val.bucketfield.BucketField.replace(/\./g, "_");
    const getuniquedata = [
      ...new Set(
        this.state.payload_data &&
          this.state.payload_data.map((item) => item[bucket_field])
      ),
    ];
    const filteredData =
      this.state.Reasonlist_default &&
      this.state.Reasonlist_default[bucket_field] &&
      this.state.Reasonlist_default[bucket_field].filter((item) =>
        getuniquedata.includes(item.item_value)
      );
    console.log(
      "this.state.Reasonlist_default[bucket_field]",
      this.state.Reasonlist_default
    );

    let isedits = this.state.Reasonlist_default && val.isedit == true && (
      <select
        className="form-control"
        style={{
          width: 120,
        }}
        defaultValue={val.value}
        value={this.state.filters[bucket_field]}
        // onChange={(event) => {
        //   handlePayloadChange(parseInt(event.target.value), val.bucketfield);
        //   //setReasonData(event.target.value);
        // }}
        onChange={(e) => this.eventNameSearch(e, bucket_field)}
      >
        <option value="">Select</option>
        {this.state.Reasonlist_default[bucket_field] ? (
          filteredData.map((list) => (
            <option value={list.item_value}>
              {dropdown_data == "V"
                ? list.item_value
                : dropdown_data == "L"
                ? list.item_name
                : dropdown_data == "B"
                ? list.item_value + "-" + list.item_name
                : list.item_name}
            </option>
          ))
        ) : (
          <>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </>
        )}
      </select>
    );
    if (val.isedit == false) {
      // let iseditfalse;
      // iseditfalse = Reasonlist.map(
      //   (list) => list.item_value == val.value && list.item_name
      // );
      console.log("Reasonlist_default11", this.state.Reasonlist_default);
      if (
        this.state.Reasonlist_default &&
        this.state.Reasonlist_default[bucket_field]
      ) {
        const matchingItem = this.state.Reasonlist_default[bucket_field].find(
          (item) => item.item_value == val.value
        );

        // Check if a matching item was found if not then show value
        if (matchingItem) {
          const itemName = matchingItem.item_name;
          isedits = itemName;
        } else {
          isedits = val.value;
        }
      } else {
        isedits = "Select";
      }
    }
    return isedits;
  };
  onLayoutChangeBody(layout, layouts) {
    console.log("onLayoutChangeBody", layout);
    this.state.bodyLayout = layout;
    console.log("bodyEle", this.state.payload_config.component_mapping.body);
    this.state.bodyLayout.map((item, index) => {
      this.state.payload_config.component_mapping.body[index]["x"] = item["x"];
      this.state.payload_config.component_mapping.body[index]["y"] = item["y"];
      this.state.payload_config.component_mapping.body[index]["w"] = item["w"];
      this.state.payload_config.component_mapping.body[index]["h"] = item["h"];
    });
    this.state.bodyLayouts = layouts;
  }
  updateFilterval = (val) => {
    if (val) {
      const json = this.state.savedFilter.filter(
        (item) => item._id.$oid === val
      );
      console.log("savedFilter", json);
      //json[0].view_config
      this.setState({
        updatecheckbox: json[0].view_config,
        updatefiltername: json[0].filtername,
        updatesearchconfig: json[0].search_config,
        updateObjectid: json[0]._id.$oid,
      });

      //      console.log("BucketField", chkdata);
      // this.setState({
      //   checkedCheckboxes: chkdata,
      // });
    }
  };
  filterchange = (val) => {
    if (val) {
      this.setState({
        viewfilter: val,
      });
      const json = this.state.savedFilter.filter(
        (item) => item._id.$oid === val
      );
      const updatedData = this.state.payload_config.field_mapping.map(
        (item) => {
          // Check if the BucketField is present in data1
          const isBucketFieldInData1 = json[0].view_config.includes(
            item.BucketField
          );

          // Update isVisible property based on the presence in data1
          return {
            ...item,
            isVisible: isBucketFieldInData1 ? "1" : "0",
          };
        }
      );

      this.setState(
        (prevState) => ({
          payload_config: {
            ...prevState.payload_config,
            field_mapping: updatedData,
          },
          searchconfig: json[0].search_config,
          search_click: 1,
        }),
        () => this.getPayloads()
      );
    } else {
      this.setState(
        {
          payload_config: this.state.defaultpayload_config,
          searchconfig: this.state.defaultsearchconfig,
          search_click: 1,
        },
        () => this.getPayloads()
      );
    }
  };

  editFilter = async () => {
    let dbname, collectionname;
    dbname = "callbacks";
    collectionname = "store_user_view";

    let objectid = this.state.updateObjectid;
    let upd_data = this.state.updated_filterdata;

    console.log("After state update:", upd_data);
    if (
      this.state.payload_config.setupdated_at &&
      this.state.payload_config.setupdated_at
    ) {
      upd_data = this.addupdateddata(upd_data);
    }

    const bucketFields = this.state.payload_config.field_mapping.map(
      (item) => item.BucketField
    );
    const addDataBeforeField = this.state.payload_config.field_mapping.find(
      (item) => item.add_data_before === "1"
    );

    // Initialize the result object
    const result = { add_data_before: {} };

    // Flag to check if any key matched
    let anyKeyMatched = false;

    // Iterate over upd_data keys
    for (const key in upd_data) {
      if (bucketFields.includes(key)) {
        if (addDataBeforeField && key === addDataBeforeField.BucketField) {
          result["add_data_before"][key] = upd_data[key];
          anyKeyMatched = true;
        } else {
          result[key] = upd_data[key];
        }
      } else {
        result[key] = upd_data[key];
      }
    }

    // If no keys matched, ensure add_data_before is an empty object
    if (!anyKeyMatched) {
      result["add_data_before"] = {};
    }
    this.setState({ isspin: true });
    const response = await update_mongopayload(
      this.state.api_token,
      dbname,
      collectionname,
      objectid,
      result
    );

    if (response.status == 1) {
      this.setState({ isspin: false });
      this.getviewcolconfig();
      this.setState({
        updatecheckbox: "",
        updatefiltername: "",
        updatesearchconfig: "",
        updateObjectid: "",
        filtercrtupd: "",
      });
      Modal.success({
        content: <pre id="json">{response.message}</pre>,
        title: "Success",
      });
      return true;
    } else {
      this.setState({ isspin: false });
      Modal.error({
        content: <pre id="json">{response.message}</pre>,
        title: "Error",
      });
      return false;
    }
  };
  getfileDetail = (fileinfo, configdata) => {
    //fileList
    this.setState({
      [configdata.BucketField]: fileinfo.fileList,
    });
  };
  onRemove = (configdata) => {
    this.setState({
      [configdata.BucketField]: "",
      hasFile: 0,
    });
  };
  beforeUpload = (file, configdata) => {
    console.log("filedata", configdata.UploadType);
    const fileExtension = file.name.split(".").pop();

    const dataArray = configdata.UploadType.split(",").map((item) =>
      item.toLowerCase()
    );
    const isValidFileType = dataArray.includes(fileExtension.toLowerCase());

    // const isValidFileType =
    //   fileExtension.toLowerCase() == configdata.UploadType.toLowerCase();

    //const isPDF = file.type === 'application/pdf';
    const isValidSize = file.size / 1024 / 1024 < configdata.UploadSize; // 2MB

    if (!isValidFileType) {
      message.error(`${file.name} is not a ${configdata.UploadType} file`);
      return Upload.LIST_IGNORE; // Prevent file from being added
    }

    if (!isValidSize) {
      message.error(`${file.name} must be smaller than 2MB`);
      return Upload.LIST_IGNORE; // Prevent file from being added
    }

    this.setState({
      [configdata.BucketField]: file,
      hasFile: 1,
    });

    return true; // Return true to allow upload if file is valid
  };
  getmassfield = (cell, index) => {
    console.log("getmassfield", cell);
    let dropdown_data = "";
    if (cell.dropDownOptions) {
      dropdown_data = cell.dropDownOptions;
    }

    console.log("dropDownOptions", dropdown_data);

    return !cell["showcondition"] || cell["showcondition"] == 0 ? (
      <div className="form-group col-auto">
        {/* {cell["fieldname"]} */}
        {cell["datatype"] === "group_id" ? (
          <div className="d-flex">
            <input
              className="form-control"
              readOnly
              value={this.state.group_id}
            />
            <button
              className="btn btn-primary ml-1"
              onClick={() => this.adduniqueid(index, cell["BucketField"])}
            >
              +
            </button>
          </div>
        ) : cell["datatype"] === "dropdownlist" ? (
          <>
            {this.state.Reasonlist && (
              <select
                className="form-control"
                //defaultValue={val.value}
                //value={ReasonData}
                style={{
                  width: 120,
                }}
                onChange={(e) =>
                  this.handlemassInputChange(
                    index,
                    cell["BucketField"],
                    parseInt(e.target.value)
                  )
                }
              >
                <option value="">{cell["fieldname"]}</option>
                {this.state.MassReasonlist[cell["BucketField"]] &&
                  this.state.MassReasonlist[cell["BucketField"]].map((list) => (
                    <option value={list.item_value}>
                      {dropdown_data == "V"
                        ? list.item_value
                        : dropdown_data == "L"
                        ? list.item_name
                        : dropdown_data == "B"
                        ? list.item_value + " - " + list.item_name
                        : list.item_name}
                    </option>
                  ))}
              </select>
            )}
          </>
        ) : cell["datatype"] === "textbox" ? (
          <input
            className="form-control"
            placeholder={cell["fieldname"]}
            value={this.state.massUpdateData[index].value}
            onChange={(e) =>
              this.handlemassInputChange(
                index,
                cell["BucketField"],
                e.target.value
              )
            }
          />
        ) : cell["datatype"] === "Uploadfile" ? (
          <>
            <Upload
              className="form-control"
              //fileList={this.state.upload_filepath}
              //onChange={(e) => this.getfileDetail(e, cell)}
              beforeUpload={(e) => this.beforeUpload(e, cell)}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload File</Button>
            </Upload>

            {this.state[cell["BucketField"]] &&
              this.state[cell["BucketField"]].map((file) => (
                <div key={file.uid} style={{ marginBottom: "8px" }}>
                  <span>
                    {file.name} - {(file.size / 1024).toFixed(2)} KB
                  </span>
                </div>
              ))}
            <span>Allow {cell["UploadType"]}</span>
          </>
        ) : cell["datatype"] === "selectoption" ? (
          <select
            className="form-control"
            onChange={(e) =>
              this.handlemassInputChange(
                index,
                cell["BucketField"],
                parseInt(e.target.value)
              )
            }
          >
            <option value="">{cell["fieldname"]}</option>
            {this.state[cell["BucketField"]] &&
              this.state[cell["BucketField"]].map((list) => (
                <option value={list.key}>
                  {dropdown_data == "V"
                    ? list.value
                    : dropdown_data == "L"
                    ? list.value
                    : dropdown_data == "B"
                    ? list.key + " - " + list.value
                    : list.value}
                </option>
              ))}
          </select>
        ) : (
          cell["datatype"] === "date" && (
            <input
              type="date"
              onChange={(e) =>
                this.handlemassInputChange(
                  index,
                  cell["BucketField"],
                  e.target.value
                )
              }
              placeholder={cell["fieldname"]}
              className="form-control"
            />
          )
        )}
      </div>
    ) : (
      <>
        {/* {console.log(
          "mass_condition_field",
          cell["mass_condition_value"]
            .split(",")
            .includes(
              this.state.massUpdateInput.length > 0 &&
                this.state.massUpdateInput
                  .find((item) => item.my_key == cell["mass_condition_field"])
                  .value.toString()
            )
        )} */}
        {console.log("this.state.massUpdateInput", this.state.massUpdateInput)}
        {cell["mass_condition_value"]
          .split(",")
          .includes(
            (
              this.state.massUpdateInput.find(
                (item) => item.my_key === cell["mass_condition_field"]
              ) || {}
            ).value
              ? (
                  this.state.massUpdateInput.find(
                    (item) => item.my_key === cell["mass_condition_field"]
                  ) || {}
                ).value.toString()
              : ""
          ) == true && (
          <div className="col-md-3">
            {cell["fieldname"]}
            {cell["datatype"] === "group_id" ? (
              <div className="d-flex">
                <input
                  className="form-control"
                  readOnly
                  value={this.state.group_id}
                />
                <button
                  className="btn btn-primary ml-1"
                  onClick={() => this.adduniqueid(index, cell["BucketField"])}
                >
                  +
                </button>
              </div>
            ) : cell["datatype"] === "dropdownlist" ? (
              <>
                {this.state.Reasonlist && (
                  <select
                    className="form-control"
                    //defaultValue={val.value}
                    //value={ReasonData}
                    style={{
                      width: 120,
                    }}
                    onChange={(e) =>
                      this.handlemassInputChange(
                        index,
                        cell["BucketField"],
                        parseInt(e.target.value)
                      )
                    }
                  >
                    <option value="">{cell["fieldname"]}</option>
                    {this.state.MassReasonlist[cell["BucketField"]] &&
                      this.state.MassReasonlist[
                        cell["BucketField"]
                      ].map((list) => (
                        <option value={list.item_value}>
                          {dropdown_data == "V"
                            ? list.item_value
                            : dropdown_data == "L"
                            ? list.item_name
                            : dropdown_data == "B"
                            ? list.item_value + " - " + list.item_name
                            : list.item_name}
                        </option>
                      ))}
                  </select>
                )}
              </>
            ) : cell["datatype"] === "textbox" ? (
              <input
                className="form-control"
                placeholder={cell["fieldname"]}
                value={this.state.massUpdateData[index].value}
                onChange={(e) =>
                  this.handlemassInputChange(
                    index,
                    cell["BucketField"],
                    e.target.value
                  )
                }
              />
            ) : cell["datatype"] === "Uploadfile" ? (
              <>
                <Upload
                  className="form-control"
                  //fileList={this.state.upload_filepath}
                  //onChange={(e) => this.getfileDetail(e, cell)}
                  beforeUpload={(e) => this.beforeUpload(e, cell)}
                  maxCount={1}
                  onRemove={() => this.onRemove(cell)}
                >
                  <Button icon={<UploadOutlined />}>Upload File</Button>
                  {this.state[cell["BucketField"]] && (
                    <div style={{ marginBottom: "8px" }}>
                      <span>
                        {(this.state[cell["BucketField"]].size / 1024).toFixed(
                          2
                        )}{" "}
                        KB
                      </span>
                    </div>
                  )

                  //console.log("filefile",this.state[cell["BucketField"]].size)
                  }
                </Upload>
                <span>Allow {cell["UploadType"]}</span>
              </>
            ) : cell["datatype"] === "selectoption" ? (
              <select
                className="form-control"
                onChange={(e) =>
                  this.handlemassInputChange(
                    index,
                    cell["BucketField"],
                    parseInt(e.target.value)
                  )
                }
              >
                <option value="">{cell["fieldname"]}</option>
                {this.state[cell["BucketField"]] &&
                  this.state[cell["BucketField"]].map((list) => (
                    <option value={list.key}>
                      {dropdown_data == "V"
                        ? list.value
                        : dropdown_data == "L"
                        ? list.value
                        : dropdown_data == "B"
                        ? list.ky + " - " + list.value
                        : list.value}
                    </option>
                  ))}
              </select>
            ) : (
              cell["datatype"] === "date" && (
                <input
                  type="date"
                  onChange={(e) =>
                    this.handlemassInputChange(
                      index,
                      cell["BucketField"],
                      e.target.value
                    )
                  }
                  placeholder={cell["fieldname"]}
                  className="form-control"
                />
              )
            )}
          </div>
        )}
      </>
    );
  };
  handlemassCheckboxChange = (fieldName) => {
    console.log("fieldName", fieldName);
    const updatedCheckboxes = this.state.selectedMassUpdateData;
    if (updatedCheckboxes.includes(fieldName)) {
      // If the checkbox is already checked, uncheck it
      const index = updatedCheckboxes.indexOf(fieldName);
      updatedCheckboxes.splice(index, 1);
    } else {
      // If the checkbox is unchecked, check it
      updatedCheckboxes.push(fieldName);
    }
    console.log("updatedCheckboxes", updatedCheckboxes);
    //setCheckedItems(updatedCheckboxes);
    if (this.state.addtosum) {
      this.getsumdata(updatedCheckboxes);
    }
    this.setState({
      selectedMassUpdateData: updatedCheckboxes,
    });
    const isCheckAll = updatedCheckboxes.length === this.state.payload_data.length;
    this.setState({ CheckAll: isCheckAll });
  };
  handleMasterCheckboxChange = (e) => {
    const isChecked = e.target.checked; // Check if the master checkbox is checked
    const { data } = this.props; // Assuming 'data' is passed as a prop to YourTableComponent

    // Update CheckAll state based on master checkbox state
    this.setState({ CheckAll: isChecked });

    // If master checkbox is checked, update checkedValues state with _id.$oid values
    if (isChecked) {
      const data = this.filterData();
      const allValues = data.map((payload) => this.getPayloadId(payload));
      this.setState({ selectedMassUpdateData: allValues });
      if (this.state.addtosum) {
        this.getsumdata(allValues);
      }
    } else {
      // If master checkbox is unchecked, reset checkedValues state to empty array
      this.setState({ selectedMassUpdateData: [] });
      if (this.state.addtosum) {
        const allValues = [];
        this.getsumdata(allValues);
      }
    }
  };

  getPayloadId = (payload) => {
    const usethisfield = this.state.payload_config.field_mapping
      .filter((field) => field.useasinput === "1")
      .map((field) => field.BucketField);
    return usethisfield.length > 0
      ? payload[usethisfield]
      : this.state.payload_config.payload_type === "M"
      ? payload["_id.$oid"] || payload._id.$oid
      : payload["key"];
  };
  getsumdata = (updatedCheckboxes) => {
    const { payload_data, addtosum } = this.state;
    const bucketField = addtosum.BucketField;
    const filteredData3 = payload_data.filter((item) =>
      updatedCheckboxes.includes(item._id.$oid)
    );
    const sum = filteredData3.reduce((acc, item) => acc + item[bucketField], 0);
    console.log("sumsum", addtosum.BucketField);
    this.setState({
      [addtosum.BucketField]: sum,
    });
  };
  removedCheckboxes=(inputcheckbox)=>{
    this.setState({
      removedCheckboxes:inputcheckbox
    })

    console.log("inputcheckbox",inputcheckbox);
    const filteredData = this.state.init_outputdata.filter(item => !inputcheckbox.includes(item._id.$oid));

    const removed_data = this.processAggData(
      filteredData,
      this.state.payload_config.field_mapping
    );
    console.log("removedCheckboxes",filteredData);
    this.setState({
      payload_data:removed_data,
      payloadglobal_data:filteredData
    })
  }
  storesubdata = (inputData)=>{
    console.log("inputString",inputData)
    const key = Object.keys(inputData)[0];
  const newValue = inputData[key];

  this.updateItem(key, newValue);
  }

  updateItem=(key, newValue)=> {
    let updatedData={};
    if(this.state.sub_data.length>0){
      updatedData = this.state.sub_data.map((item) => {
        if (item.key === key) {
          return { ...item, value: newValue };
        } else {
          return item;
        }
      });
    } else {
      updatedData = [{
        ...this.state.sub_data,
        [key]: newValue,
      }];
    }
    // Update the state or data structure accordingly
    // For example, if using React:
    this.setState({ sub_data: updatedData },()=>console.log("sub_data",this.state.sub_data));
    // Or for a plain JavaScript array:
    //data = updatedData;
  }
  getfieldsum = (filteredData, BucketField) => {
    const fieldsum = filteredData.reduce(
      (acc, item) => acc + item[BucketField],
      0
    );
    return "Rs. " + fieldsum.toLocaleString("en-IN");
  };
  freeSearch() {
    const results = [];
    const data = this.state.payload_data;
    if (this.state.mobilequery) {
      data.forEach((item) => {
        const keys = Object.keys(item);

        for (let i = 0; i < keys.length; i++) {
          const value = item[keys[i]];

          if (
            typeof value === "string" &&
            value.toLowerCase().includes(this.state.mobilequery.toLowerCase())
          ) {
            results.push(item);
            break;
          } else if (typeof value === "number" || typeof value === "boolean") {
            if (value.toString().includes(this.state.mobilequery)) {
              results.push(item);
              break;
            }
          } else if (Array.isArray(value)) {
            for (let j = 0; j < value.length; j++) {
              const arrayValue = value[j];
              if (
                typeof arrayValue === "string" &&
                arrayValue
                  .toLowerCase()
                  .includes(this.state.mobilequery.toLowerCase())
              ) {
                results.push(item);
                break;
              } else if (
                typeof arrayValue === "number" ||
                typeof arrayValue === "boolean"
              ) {
                if (arrayValue.toString().includes(this.state.mobilequery)) {
                  results.push(item);
                  break;
                }
              }
            }
          }
        }
      });

      return results;
    } else {
      return data;
    }
  }
  onDrag = (e, data) => {
    this.setState({
      currentPosition: { xRate: data.lastX, yRate: data.lastY },
    });
  };
  render() {
    const updateUniqueData = this.state.updateUniqueData || [];
    const filteredData = this.filterData();
    let mobilefilterdata = [];
    if (this.state.isMobile) {
      mobilefilterdata = this.freeSearch();
    }
    console.log("payload_config.field_mapping--", this.state.ExpandedRow);
    return (
      // <div className="container">
      <div className="mr-2 ml-2">
         <Modal
          open={this.state.isExprow}
          onCancel={() => this.setState({
            isExprow: false,
          })}
          footer={[]}
          width="auto"
          id={this.state.selectedrowindex}
        >
          <div id={this.state.selectedrowindex} class="cell-1 row-child" style={{display:"block"}}>
            <DetailComponent
              detailData={this.state.ExpandedRow}
              payload_config={this.state.subpayload_config}
              api_token={this.state.api_token}
              effective_designation_id={this.props.location.state.effective_designation_id}
              fetch_onload={true}
              storesubdata={this.storesubdata}
              selectedrowindex={this.state.selectedrowindex}
              filtered_condition={this.state.filtered_condition}
              sub_data={this.state.sub_data||[]}
              removedCheckboxes={this.removedCheckboxes}
            />
          </div>
        </Modal>
        <div
          className={
            this.state.sumDetail
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-boxsum"
          style={{ position: "absolute" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({
                    sumDetail: false,
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="row">
                {this.state.config_sum &&
                  this.state.config_sum.map((totdata, index) => (
                    <>
                      <div
                        className="col-md-12 detail-amt-border p-1"
                        style={{ color: totdata.color || "#000000" }}
                      >
                        <div className="w-50 float-left">
                          <span
                            className="mr-3"
                            style={{ color: totdata.color || "#000000" }}
                          >
                            <span>{totdata.name}</span>
                          </span>
                        </div>
                        <div className="w-50 text-right float-left">
                          <span
                            className="mr-3 onclick-menu"
                            style={{ color: totdata.color || "#000000" }}
                          >
                            <strong>{totdata.sum}</strong>
                          </span>
                        </div>
                      </div>
                      <div className="row category-data w-100">
                        {totdata.breakdown &&
                          totdata.breakdown.map((breakdata) => (
                            <>
                              <div className="col-md-12 categorysub-data">
                                <div className="w-75 float-left">
                                  {breakdata.name}
                                </div>
                                <div className="w-25 text-right float-left">
                                  <strong
                                    style={{
                                      color:
                                        breakdata.operator &&
                                        breakdata.operator == "+"
                                          ? "#008000"
                                          : breakdata.operator == "-"
                                          ? "#FF0000"
                                          : "#000000",
                                    }}
                                  >
                                    {breakdata.operator &&
                                      "(" + breakdata.operator + ")"}
                                    {breakdata.sum}
                                  </strong>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isError
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({
                    isError: false,
                  });
                  window.location.reload();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div>{this.state.isErrorMessage}</div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isMassUpdatePopup
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({
                    isMassUpdatePopup: false,
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="form-row">
                {this.state.updateUniqueData.length > 0 &&
                  this.state.updateUniqueData.map((cell, index) => (
                    <>
                      {cell["showon"] &&
                        cell["showon"] == "0" &&
                        this.getmassfield(cell, index)}
                    </>
                  ))}
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({
                      isMassupdate: true,
                      isMassupdclick: true,
                      isMassUpdatePopup: false,
                    });
                    this.updatemassdata();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isSettings
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    isSettings: false,
                    searchconfig: this.state.defaultsearchconfig,
                    filtercrtupd: "",
                  })
                }
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {/* <p>Do you want to register the household for NACH?</p> */}
              <div className="row">
                {!this.state.filtercrtupd && (
                  <>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary form-control"
                        onClick={(e) =>
                          this.setState({
                            filtercrtupd: "C",
                          })
                        }
                      >
                        Create
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary form-control"
                        onClick={(e) =>
                          this.setState({
                            filtercrtupd: "U",
                          })
                        }
                      >
                        Update
                      </button>
                    </div>
                  </>
                )}
              </div>
              {this.state.filtercrtupd == "C" && (
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <input
                      className="form-control"
                      placeholder="Enter Name"
                      onChange={(e) =>
                        this.setState({ filtername: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <QueryBuilder
                      fields={
                        this.state.search_fields.length > 0 &&
                        this.state.search_fields
                      }
                      operators={this.state.Operators}
                      addRuleTonewgroupid
                      controlClassnames={{
                        queryBuilder: "queryBuilder-branches",
                      }}
                      query={this.state.searchconfig}
                      onQueryChange={(q) =>
                        this.setState({
                          searchconfig: q,
                        })
                      }
                      controlElements={{
                        valueEditor: this.CustomValueEditor,
                      }}
                    />
                  </div>
                  {this.state.HeaderData.map((field, index) => (
                    <div key={index} className="col-md-4">
                      <div>
                        <input
                          type="checkbox"
                          className="mr-1 mobilecheckbox"
                          checked={this.state.checkedCheckboxes.includes(
                            field.BucketField
                          )}
                          onChange={() =>
                            this.handleCheckboxChange(field.BucketField)
                          }
                        />
                        <label>{field.fieldname}</label>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 row">
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary form-control"
                        disabled={this.state.checkedCheckboxes.length == 0}
                        onClick={() => {
                          this.savepreferences();
                          this.setState({
                            isSettings: false,
                            filtercrtupd: "",
                          });
                        }}
                      >
                        Create
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-secondary form-control"
                        onClick={() =>
                          this.setState({
                            isSettings: false,
                            searchconfig: this.state.defaultsearchconfig,
                            filtercrtupd: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.filtercrtupd == "U" && (
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <select
                      className="form-control"
                      onChange={(e) => this.updateFilterval(e.target.value)}
                    >
                      <option value="">Select</option>
                      {this.state.savedFilter &&
                        this.state.savedFilter.map((data) => (
                          <option value={data._id.$oid}>
                            {data.filtername}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-12 mb-2">
                    <input
                      className="form-control"
                      placeholder="Enter Name"
                      value={this.state.updatefiltername}
                      onChange={(e) =>
                        this.setState({ updatefiltername: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <QueryBuilder
                      fields={
                        this.state.search_fields.length > 0 &&
                        this.state.search_fields
                      }
                      operators={this.state.Operators}
                      addRuleTonewgroupid
                      controlClassnames={{
                        queryBuilder: "queryBuilder-branches",
                      }}
                      query={this.state.updatesearchconfig}
                      onQueryChange={(q) =>
                        this.setState({
                          updatesearchconfig: q,
                        })
                      }
                      controlElements={{
                        valueEditor: this.CustomValueEditor,
                      }}
                    />
                  </div>
                  {this.state.HeaderData.map((field, index) => (
                    <div key={index} className="col-md-4">
                      <div>
                        <input
                          type="checkbox"
                          className="mr-1 mobilecheckbox"
                          checked={
                            this.state.updatecheckbox &&
                            this.state.updatecheckbox.includes(
                              field.BucketField
                            )
                          }
                          onChange={() =>
                            this.handleCheckboxChangeupdate(field.BucketField)
                          }
                        />
                        <label>{field.fieldname}</label>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 row">
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary form-control"
                        disabled={this.state.checkedCheckboxes.length == 0}
                        onClick={() => {
                          this.updateFilter();
                          this.setState({ isSettings: false });
                        }}
                      >
                        Update
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-secondary form-control"
                        onClick={() =>
                          this.setState({
                            isSettings: false,
                            searchconfig: this.state.defaultsearchconfig,
                            filtercrtupd: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.isspin && (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        )}
        <div className="payload_view_navbar text-center">
          <button
            className="button back_button"
            onClick={() => this.props.history.goBack()}
          >
            <img src={require("../assets/images/back.png")} alt="" />
          </button>
          <h4>
            {this.state.view_name ? this.state.view_name : "View"} (
            {this.state.payload_data && this.state.payload_data.length})
          </h4>
          <button
            className="button"
            onClick={() =>
              logout(this.state.otherScreen, this.state.no_of_page)
            }
          >
            <img src={require("../assets/images/power-off.png")} alt="" />
          </button>
        </div>

        <div className="payload_view_main_block">
          <div className="payload_view_table_block">
            {this.state.isMobile &&
            (this.state.payload_config.hide_filter == "2" ||
              this.state.payload_config.hide_filter == "3") ? (
              ""
            ) : (
              <div id="accordion">
                <div class="card">
                  <div class="card-header p-0" id="headingOne">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Filters
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <div className="row">
                        {this.state.payload_config.payload_type == "B" ? (
                          <>
                            <div className="col-md-3">
                              <DatePicker
                                //defaultValue={moment().subtract(6, "months")}
                                format={dateFormatList}
                                onChange={this.changedate}
                                placeholder="Start Date"
                              />
                              <button
                                className="btn btn-primary ml-2"
                                disabled={!this.state.filterDate}
                                onClick={() => {
                                  this.setState({ search_click: 1 }, () =>
                                    this.getPayloads()
                                  );
                                }}
                              >
                                Search
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-12">
                              <div className="d-flex flex-row-reverse col-md-4 float-right">
                                <div>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => this.showpopup()}
                                  >
                                    <SettingOutlined />
                                  </button>
                                </div>
                                <div className="mr-2">
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      this.filterchange(e.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    {this.state.savedFilter &&
                                      this.state.savedFilter.map((data) => (
                                        <option value={data._id.$oid}>
                                          {data.filtername}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              {this.state.payload_config.hide_filter == "1" ||
                              this.state.payload_config.hide_filter == "3" ? (
                                ""
                              ) : (
                                <>
                                  {this.state.search_fields.length > 0 && (
                                    <>
                                      <div className="col-md-8">
                                        <QueryBuilder
                                          fields={
                                            this.state.search_fields.length >
                                              0 && this.state.search_fields
                                          }
                                          operators={this.state.Operators}
                                          addRuleTonewgroupid
                                          controlClassnames={{
                                            queryBuilder:
                                              "queryBuilder-branches",
                                          }}
                                          query={
                                            this.state.isSettings
                                              ? this.state.defaultsearchconfig
                                              : this.state.searchconfig
                                          }
                                          onQueryChange={(q) =>
                                            this.setState({
                                              searchconfig: q,
                                            })
                                          }
                                          controlElements={{
                                            valueEditor: this.CustomValueEditor,
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-3">
                                        <button
                                          className="btn btn-primary mt-2"
                                          disabled={!this.state.filterDate}
                                          onClick={() => {
                                            this.setState(
                                              { search_click: 1 },
                                              () => this.getPayloads()
                                            );
                                          }}
                                        >
                                          Search
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(this.state.isMobile &&
              (this.state.payload_config.hide_exportBtn == "2" ||
                this.state.payload_config.hide_exportBtn == "3")) ||
            this.state.payload_config.hide_exportBtn == 1 ||
            this.state.payload_config.hide_exportBtn == 3 ? (
              ""
            ) : (
              <div className="d-flex flex-row-reverse mt-1">
                <button
                  className="download-table-xls-button mb-1 btn btn-primary col-md-1"
                  onClick={() => this.handleClick()}
                >
                  <FileExcelOutlined />
                </button>

                {/* <div className="col-md-2">
                <strong>
                  Total Record :{" "}
                  {this.state.payload_data && this.state.payload_data.length}
                </strong>
              </div> */}
                <div style={{ display: "none" }}>
                  <ReactHTMLTableToExcel
                    id="table-xls-button"
                    className="download-table-xls-button mb-2 btn btn-primary"
                    table="my-table"
                    filename={
                      this.state.view_name +
                      "_" +
                      moment().format("YYYYMMDD_HH_mm_ss")
                    }
                    sheet="sheet1"
                    buttonText="Export to Excel"
                    isExporting={this.state.isExporting}
                  />
                </div>
              </div>
            )}
            {/* {this.state.effective_designation_id == "1" && (
              <div className="text-center m-2">
                <h3>Waiting For FCM</h3>
              </div>
            )} */}
            <div className="form-row">
              {updateUniqueData.length > 0 &&
                updateUniqueData.map((cell, index) => (
                  <>
                    {cell["showon"] &&
                      cell["showon"] == "1" &&
                      this.getmassfield(cell, index)}
                  </>
                ))}
              {updateUniqueData.length > 0 && (
                <div className="form-group col-auto">
                  <button
                    className="btn btn-primary"
                    disabled={
                      this.state.isMassupdclick ||
                      this.state.selectedMassUpdateData.length == 0 ||
                      this.state.massUpdateInput.length == 0
                    }
                    onClick={() => {
                      this.setState({
                        isMassUpdatePopup: true,
                      });
                    }}
                  >
                    Update
                  </button>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <strong>
                {this.state.filter_name &&
                  `Entries eligible for ${this.state.filter_name} (${this.state.filtered_cnt} Records)`}
              </strong>
            </div>
            {this.state.config_sum && (
              <Draggable
                ref={ref}
                position={{
                  x: this.state.currentPosition.xRate,
                  y: this.state.currentPosition.yRate,
                }}
                axis="x"
                onDrag={this.onDrag}
                onTouchStart={() => {
                  console.log("ontouchstart");
                  //setborder(true);
                }}
                onTouchEnd={() => {
                  console.log("ontouchend");
                  //setborder(false);
                }}
                onMouseUp={() => {
                  console.log("onmouseup");
                  //setborder(true);
                }}
                onMouseDown={() => {
                  console.log("onmousedown");
                  //setborder(false);
                }}
                handle=".handle"
              >
                <div className="support_section">
                  <div className="handle support_drag_handle support_drag_handle_hovered">
                    <img src={DragIcon} draggable={false} alt="" />
                  </div>
                  <img
                    src={require("../assets/images/cash_status.svg")}
                    alt=""
                    onClick={() =>
                      this.setState({
                        sumDetail: true,
                      })
                    }
                    height="40px"
                    width="40px"
                  />
                </div>
              </Draggable>
            )}

            {this.state.isMobile && (
              <div className="form-row">
                <div className="form-group col-md-12">
                  <input
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ mobilequery: e.target.value })
                    }
                    placeholder="Search Here"
                  />
                </div>
                <div className="form-group col-auto w-75">
                  {this.state.addtosum && (
                    <strong>
                      Checked Total :{" "}
                      {this.state[this.state.addtosum.BucketField] &&
                        this.state[
                          this.state.addtosum.BucketField
                        ].toLocaleString("en-IN")}
                    </strong>
                  )}
                </div>
                <div className="form-group col-auto w-25 text-right">
                  <input
                    type="checkbox"
                    className="mobilecheckbox"
                    checked={this.state.CheckAll}
                    onChange={this.handleMasterCheckboxChange}
                    ref={(ref) => {
                      this["checkall"] = ref;
                    }}
                    style={{ display: "none" }}
                  />
                  {/* <Button
                    icon={
                      this.state.CheckAll ? (
                        <CloseOutlined />
                      ) : (
                        <CheckOutlined />
                      )
                    }
                    onClick={() => {
                      this["checkall"].click();
                    }}
                    type={!this.state.CheckAll ? "primary" : "default"}
                  ></Button> */}
                  {this.state.CheckAll ? (
                    ""
                  ) : (
                    <img
                      src={require("../assets/images/checked.svg")}
                      className="checked_img"
                      alt=""
                      onClick={() => {
                        this["checkall"].click();
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {(this.state.payload_config.component_mapping != null ||
              this.state.payload_config.component_mapping != undefined) &&
            this.state.isMobile ? (
              mobilefilterdata.map((rowdata, rowindex) => {
                return (
                  <div className="mt-2 mb-3">
                    <ResponsiveReactGridLayout
                      style={{ zIndex: 1 }}
                      cols={{ lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 }}
                      breakpoints={{
                        lg: 1200,
                        md: 996,
                        sm: 768,
                        xs: 480,
                        xxs: 0,
                      }}
                      rowHeight={30}
                      layouts={this.state.layouts}
                      // onLayoutChange={(layout, layouts) =>
                      // this.onLayoutChange(layout, layouts)
                      // }
                      isDraggable={false}
                      isResizable={false}
                    >
                      {updateUniqueData.length > 0 && (
                        <div
                          key="0"
                          // data-grid={{
                          //   x: 0,
                          //   y: 0,
                          //   w: 1,
                          //   h: 1,
                          // }}
                          className="headerText notransform w-100 text-right pt-1"
                        >
                          <input
                            checked={this.state.selectedMassUpdateData.includes(
                              this.getPayloadId(rowdata)
                            )}
                            type="checkbox"
                            className="mobilecheckbox"
                            value={
                              this.state.payload_config.field_mapping.filter(
                                (field) => field.useasinput === "1"
                              ) == true
                                ? rowdata[
                                    this.state.payload_config.field_mapping
                                      .filter(
                                        (field) => field.useasinput === "1"
                                      )
                                      .map((field) => field.BucketField)
                                  ]
                                : this.state.payload_config.payload_type == "M"
                                ? rowdata["_id.$oid"]
                                  ? rowdata["_id.$oid"]
                                  : rowdata._id.$oid
                                : rowdata["key"]
                            }
                            onChange={(e) =>
                              this.handlemassCheckboxChange(e.target.value)
                            }
                          />
                          {this.state.payload_config.view_type == "AV" && (
                            <span
                              data-toggle="collapse"
                              data-target={"#" + rowindex}
                              onClick={() => {
                                this.updaterowindex(rowindex);
                                this.handleRowClick(rowdata);
                              }}
                              className="exprowbtn"
                            >
                              {/* <img src={require("../assets/images/plusicon.png")} alt="" /> */}
                              +
                            </span>
                          )}
                        </div>
                      )}
                      {this.state.payload_config.component_mapping.header.map(
                        (rowbody, rowindexbody) => {
                          // console.log(
                          //   "rowindexbodyCnt",
                          //   this.state.payload_config.component_mapping.body
                          //     .length
                          // );
                          // const fielddataArray = this.state.payload_config.component_mapping.body.map(
                          //   (item) => JSON.parse(item.fielddata)
                          // );

                          // const filtered = this.state.payload_config.field_mapping.filter(item =>
                          //   fielddataArray.some(d2 => d2.BucketField === item.BucketField)
                          // );

                          //console.log("fielddataArray", filtered);
                          // const fielddataJsonString = {
                          //   field_mapping: filtered,
                          // };
                          // const parsedFielddata = JSON.parse(rowbody.fielddata);
                          // const bucketFieldToMatch =
                          //   parsedFielddata.BucketField;

                          // // Find the corresponding object in data array
                          // const body_data = this.state.payload_config.field_mapping.find(
                          //   (item) => item.BucketField === bucketFieldToMatch
                          // );

                          const fielddataObj = JSON.parse(rowbody.fielddata);

                          // Find the corresponding object in the data array
                          const matchingData = this.state.payload_config.field_mapping.find(
                            (item) =>
                              item.BucketField === fielddataObj.BucketField
                          );

                          // If a matching object is found, update the DisableOnFieldValue
                          if (matchingData) {
                            fielddataObj.showLabel = matchingData.showLabel;
                            // Update initialData2 with the modified fielddata
                            rowbody.fielddata = JSON.stringify(fielddataObj);
                          }

                          // Log the updated initialData2
                          console.log("rowindexbody", rowbody);
                          return (
                            <div
                              key={rowindexbody}
                              data-grid={{
                                x: rowbody.x,
                                y: rowbody.y,
                                w: rowbody.w,
                                h: rowbody.h,
                              }}
                              className="headerText mt-2"
                            >
                              <EditableRow
                                key={rowindexbody}
                                //payload_config={fielddataJsonString}
                                payload_config={this.state.payload_config}
                                payload={rowdata}
                                disabled={this.state.disabled}
                                setDisabled={this.setDisabled}
                                api_token={this.state.api_token}
                                getPayloads={this.getPayloads}
                                DeletePayload={this.DeletePayload}
                                DeleteMongoPayload={this.DeleteMongoPayload}
                                rowindex={rowindex}
                                effective_designation_id={
                                  this.state.effective_designation_id
                                }
                                Reasonlist={this.state.Reasonlist_default}
                                subpayload_config={this.state.subpayload_config}
                                unique_groups={this.state.unique_groups}
                                supervisor_branch_code={
                                  this.state.supervisor_branch_code
                                }
                                isMassupdate={this.state.isMassupdate}
                                selectedMassUpdateData={
                                  this.handlemassCheckboxChange
                                }
                                updateUniqueData={updateUniqueData}
                                CheckAll={this.state.CheckAll}
                                props={this.props}
                                isspin={this.state.isspin}
                                view_type="mobile"
                                view_for="mobhead"
                                totcnt={
                                  this.state.payload_config.component_mapping
                                    .body.length
                                }
                                grid_position={rowbody}
                                rowindexbody={rowindexbody}
                                isChecked={this.state.selectedMassUpdateData.includes(
                                  this.getPayloadId(rowdata)
                                )}
                                geturl={this.getDataBetweenBraces}
                                addupdateddata={this.addupdateddata}
                                handleRowClick={this.handleRowClick}
                                isVisibleEdit={this.state.isVisibleEdit}
                                isMobileView={this.state.isMobile}
                                isExprowstatus={this.state.isExprow}
                                expandedRow={this.state.ExpandedRow}
                                selectedrowindex={this.state.selectedrowindex}
                                updaterowindex={this.updaterowindex}
                                storesubdata={this.storesubdata}
                                filtered_condition={
                                  this.state.filtered_condition
                                }
                                sub_data={this.state.sub_data}
                                removedCheckboxes={this.removedCheckboxes}
                              />
                            </div>
                          );
                        }
                      )}
                    </ResponsiveReactGridLayout>
                    {this.state.payload_config.component_mapping.body.length >
                      0 && (
                      <ResponsiveReactGridLayout
                        className="pv-block-body-dd"
                        cols={{ lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 }}
                        breakpoints={{
                          lg: 1200,
                          md: 996,
                          sm: 768,
                          xs: 480,
                          xxs: 0,
                        }}
                        rowHeight={30}
                        layouts={this.state.bodyLayouts}
                        onLayoutChange={(layout, layouts) =>
                          this.onLayoutChangeBody(layout, layouts)
                        }
                        isResizable={false}
                        isDraggable={false}
                      >
                        {this.state.payload_config.component_mapping.body.map(
                          (rowbody, rowindexbody) => {
                            // console.log(
                            //   "rowindexbodyCnt",
                            //   this.state.payload_config.component_mapping.body
                            //     .length
                            // );
                            // const fielddataArray = this.state.payload_config.component_mapping.body.map(
                            //   (item) => JSON.parse(item.fielddata)
                            // );

                            // const filtered = this.state.payload_config.field_mapping.filter(item =>
                            //   fielddataArray.some(d2 => d2.BucketField === item.BucketField)
                            // );

                            //console.log("fielddataArray", filtered);
                            // const fielddataJsonString = {
                            //   field_mapping: filtered,
                            // };
                            // const parsedFielddata = JSON.parse(rowbody.fielddata);
                            // const bucketFieldToMatch =
                            //   parsedFielddata.BucketField;

                            // // Find the corresponding object in data array
                            // const body_data = this.state.payload_config.field_mapping.find(
                            //   (item) => item.BucketField === bucketFieldToMatch
                            // );

                            const fielddataObj = JSON.parse(rowbody.fielddata);

                            // Find the corresponding object in the data array
                            const matchingData = this.state.payload_config.field_mapping.find(
                              (item) =>
                                item.BucketField === fielddataObj.BucketField
                            );

                            // If a matching object is found, update the DisableOnFieldValue
                            if (matchingData) {
                              fielddataObj.showLabel = matchingData.showLabel;
                              // Update initialData2 with the modified fielddata
                              rowbody.fielddata = JSON.stringify(fielddataObj);
                            }

                            // Log the updated initialData2
                            console.log("rowindexbody", rowbody);
                            return (
                              <div
                                key={rowindexbody}
                                data-grid={{
                                  x: rowbody.x,
                                  y: rowbody.y,
                                  w: rowbody.w,
                                  h: rowbody.h,
                                }}
                                className="bodytext"
                              >
                                <EditableRow
                                  key={rowindexbody}
                                  //payload_config={fielddataJsonString}
                                  payload_config={this.state.payload_config}
                                  payload={rowdata}
                                  disabled={this.state.disabled}
                                  setDisabled={this.setDisabled}
                                  api_token={this.state.api_token}
                                  getPayloads={this.getPayloads}
                                  DeletePayload={this.DeletePayload}
                                  DeleteMongoPayload={this.DeleteMongoPayload}
                                  rowindex={rowindex}
                                  effective_designation_id={
                                    this.state.effective_designation_id
                                  }
                                  Reasonlist={this.state.Reasonlist_default}
                                  subpayload_config={
                                    this.state.subpayload_config
                                  }
                                  unique_groups={this.state.unique_groups}
                                  supervisor_branch_code={
                                    this.state.supervisor_branch_code
                                  }
                                  isMassupdate={this.state.isMassupdate}
                                  selectedMassUpdateData={
                                    this.handlemassCheckboxChange
                                  }
                                  updateUniqueData={updateUniqueData}
                                  CheckAll={this.state.CheckAll}
                                  props={this.props}
                                  isspin={this.state.isspin}
                                  view_type="mobile"
                                  view_for="mobbody"
                                  totcnt={
                                    this.state.payload_config.component_mapping
                                      .body.length
                                  }
                                  grid_position={rowbody}
                                  rowindexbody={rowindexbody}
                                  isChecked={this.state.selectedMassUpdateData.includes(
                                    this.getPayloadId(rowdata)
                                  )}
                                  geturl={this.getDataBetweenBraces}
                                  addupdateddata={this.addupdateddata}
                                  handleRowClick={this.handleRowClick}
                                  isVisibleEdit={this.state.isVisibleEdit}
                                  isMobileView={this.state.isMobile}
                                  isExprowstatus={this.state.isExprow}
                                  expandedRow={this.state.ExpandedRow}
                                  selectedrowindex={this.state.selectedrowindex}
                                  updaterowindex={this.updaterowindex}
                                  storesubdata={this.storesubdata}
                                  filtered_condition={
                                    this.state.filtered_condition
                                  }
                                  sub_data={this.state.sub_data}
                                  removedCheckboxes={this.state.removedCheckboxes}
                                />
                              </div>
                            );
                          }
                        )}
                      </ResponsiveReactGridLayout>
                    )}
                    {/* <div className="pv-block-body"></div> */}
                  </div>
                );
              })
            ) : (
              <div className="tableFixHead">
                <table
                  className="payload_view_table text-center table table-hover table-responsive"
                  id="my-table"
                >
                  <thead>
                    <tr>
                      {updateUniqueData.length > 0 && (
                        <th>
                          {/* <input
                          type="checkbox"
                          value="checkall"
                          onChange={(e) =>
                            this.setState({
                              CheckAll: !this.state.CheckAll,
                            })
                          }
                        /> */}
                          <input
                            type="checkbox"
                            className="mobilecheckbox"
                            checked={this.state.CheckAll}
                            onChange={this.handleMasterCheckboxChange}
                          />
                          <div>&nbsp;</div>
                        </th>
                      )}
                      {this.state.payload_config.field_mapping.map(
                        (cell, index) => {
                          return (
                            <>
                              {cell.isVisible == "1" && (
                                <th key={cell.fieldname}>
                                  {cell.fieldname}
                                  {cell.isRequired == 1 && <span> *</span>}
                                  {!this.state.isExporting && (
                                    <>
                                      <br />

                                      {cell.datatype == "dropdownlist" ? (
                                        <this.SearchDropdownlist
                                          className={
                                            !this.state.isExporting
                                              ? "form-control form-control-sm"
                                              : "hidden"
                                          }
                                          bucketfield={cell}
                                          isedit={true}
                                        />
                                      ) : (
                                        <input
                                          placeholder={cell.fieldname}
                                          name={cell.fieldname}
                                          type="text"
                                          className={
                                            !this.state.isExporting
                                              ? "form-control form-control-sm"
                                              : "hidden"
                                          }
                                          value={this.state.searchQuery}
                                          onChange={(e) =>
                                            this.eventNameSearch(
                                              e,
                                              cell.BucketField
                                            )
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                  {cell.addtosum &&
                                  cell.addtosum == "1" &&
                                  filteredData.length > 0 ? (
                                    this.getfieldsum(
                                      filteredData,
                                      cell.BucketField
                                    )
                                  ) : (
                                    <div>&nbsp;</div>
                                  )}
                                </th>
                              )}
                            </>
                          );
                        }
                      )}
                      {/* <th onClick={this.handleSort}>
                        Date Time{" "}
                        <span
                          className={
                            this.state.sortOrder == "asc"
                              ? "up-arrow"
                              : "down-arrow"
                          }
                        ></span>
                      </th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0 ? (
                      filteredData.map((row, rowindex) => {
                        return (
                          <EditableRow
                            key={row.key}
                            payload_config={this.state.payload_config}
                            payload={row}
                            disabled={this.state.disabled}
                            setDisabled={this.setDisabled}
                            api_token={this.state.api_token}
                            getPayloads={this.getPayloads}
                            DeletePayload={this.DeletePayload}
                            DeleteMongoPayload={this.DeleteMongoPayload}
                            rowindex={rowindex}
                            effective_designation_id={
                              this.state.effective_designation_id
                            }
                            Reasonlist={this.state.Reasonlist_default}
                            subpayload_config={this.state.subpayload_config}
                            unique_groups={this.state.unique_groups}
                            supervisor_branch_code={
                              this.state.supervisor_branch_code
                            }
                            isMassupdate={this.state.isMassupdate}
                            selectedMassUpdateData={
                              this.handlemassCheckboxChange
                            }
                            updateUniqueData={updateUniqueData}
                            CheckAll={this.state.CheckAll}
                            props={this.props}
                            isspin={this.state.isspin}
                            view_type="table"
                            view_for="table"
                            grid_position={row}
                            isChecked={this.state.selectedMassUpdateData.includes(
                              this.getPayloadId(row)
                            )}
                            geturl={this.getDataBetweenBraces}
                            addupdateddata={this.addupdateddata}
                            handleRowClick={this.handleRowClick}
                            isVisibleEdit={this.state.isVisibleEdit}
                            isMobileView={this.state.isMobile}
                            isExprowstatus={this.state.isExprow}
                            expandedRow={this.state.ExpandedRow}
                            selectedrowindex={this.state.selectedrowindex}
                            updaterowindex={this.updaterowindex}
                            storesubdata={this.storesubdata}
                            filtered_condition={this.state.filtered_condition}
                            sub_data={this.state.sub_data}
                            removedCheckboxes={this.state.removedCheckboxes}
                          />
                        );
                      })
                    ) : (
                      <div className="text-center font-bold">No Data Found</div>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <div className="text-center">
              {this.state.payload_config.payload_type == "M" &&
                this.state.payload_config.hide_loadmore &&
                this.state.payload_config.hide_loadmore == "0" && (
                  <button
                    className="btn btn-primary mt-2"
                    disabled={
                      this.state.disable_loadmore ||
                      this.state.payload_data.length == 0
                    }
                    onClick={() => {
                      this.setState({ load_more: 1 }, () => this.getPayloads());
                    }}
                  >
                    Load More
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function EditableRow({
  api_token,
  payload_config,
  payload,
  disabled,
  setDisabled,
  getPayloads,
  DeletePayload,
  DeleteMongoPayload,
  rowindex,
  effective_designation_id,
  Reasonlist,
  subpayload_config,
  unique_groups,
  supervisor_branch_code,
  isMassupdate,
  selectedMassUpdateData,
  updateUniqueData,
  CheckAll,
  props,
  isspindata,
  view_type,
  view_for,
  totcnt,
  grid_position,
  rowindexbody,
  isChecked,
  geturl,
  addupdateddata,
  handleRowClick,
  isVisibleEdit,
  isMobileView,
  isExprowstatus,
  expandedRow,
  selectedrowindex,
  updaterowindex,
  storesubdata,
  filtered_condition,
  sub_data,
  removedCheckboxes
}) {
  const [defaultpayload_config, setdefaultpayload_config] = useState(
    payload_config
  );
  const [edit, setEdit] = useState(false);
  const [updatedPayload, setUpdatedPayload] = useState({});
  const [ImageView, setImageView] = useState();
  const [isImageView, setisImageView] = useState(false);
  const [isShowData, setisShowData] = useState(false);
  const [isPayloadView, setisPayloadView] = useState(false);
  const [PayloadViewData, setPayloadViewData] = useState();
  const [ShowData, setShowData] = useState(false);
  const [ReasonData, setReasonData] = useState();
  const [isFamily, setisFamily] = useState(false);
  const [familyData, setfamilyData] = useState();
  const [change_data, setchange_data] = useState({});
  const [change_blockdelete, setchange_blockdelete] = useState();
  //const [expandedRow, setExpandedRow] = useState(null);
  const [isgroupid, setisgroupid] = useState(false);
  const [groupid, setgroupid] = useState();
  const [blockData, setblockData] = useState();
  const [isblockDataInput, setisblockDataInput] = useState(false);
  const [isBlock, setisBlock] = useState(false);
  const [DeleteBlock, setDeleteBlock] = useState(0);
  const [DeleteBucketField, setDeleteBucketField] = useState();
  const [updategroupid, setupdategroupid] = useState(unique_groups);
  const [updateReasonlist, setupdateReasonlist] = useState();
  const [mand_fld, setmand_fld] = useState();
  const [newfield_mapping, setnewfield_mapping] = useState();
  const [newfieldEdit_mapping, setnewfieldEdit_mapping] = useState();
  const [fieldtoupdate, setfieldtoupdate] = useState();
  const [tenure, settenure] = useState();
  const [productidtoupdate, setproductidtoupdate] = useState();
  const [productlist, setproductlist] = useState();
  const [errorMsg, seterrorMsg] = useState();
  const [isProductList, setisProductList] = useState(false);
  const [selectedProduct, setselectedProduct] = useState();
  const [updateAmount, setupdateAmount] = useState(0);
  //const [checkedItems, setCheckedItems] = useState(selectedMassUpdateData);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [ActionFormType, setActionFormType] = useState();
  const [ModalForm, setModalForm] = useState(false);
  const [replacedFormrow, setreplacedFormrow] = useState();
  const [replacementValues, setreplacementValues] = useState();
  const [isModalFormUpdated, setIsModalFormUpdated] = useState(false);
  const [isspin, setisspin] = useState(isspindata);
  const [onEdit, setonEdit] = useState(true);
  const [isAdd, setisAdd] = useState(false);
  const [DisableBtn, setDisableBtn] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [PayloadRequired, setPayloadRequired] = useState();
  const [isError, setisError] = useState(false);
  const [isErrorValue, setisErrorValue] = useState();
  const [isOpenModal, setisOpenModal] = useState(false);
  const [actionValue, setactionValue] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const [names, setNames] = useState({});
  const [isExprow, setisExprow] = useState(false);
  console.log("field_mappinglist", selectedrowindex);
  useEffect(() => {
    const allCheckboxValues = CheckAll ? [] : getDataIds(); // Replace with your dynamic data IDs
    setCheckboxValues(allCheckboxValues);
    getdatafromAPI();
  }, []);
  useEffect(() => {
    if(!isExprow && expandedRow.length>0)
    {
      console.log("isExprow",expandedRow);
      setisExprow(isExprowstatus);
    }
  }, [expandedRow]);

  const getdatafromAPI = async () => {
    const getfromapidata = payload_config.field_mapping.filter(
      (fields) => fields.getfromapi == "1"
    );
    // const apibucketfield = converttoarray(
    //   getfromapidata[0].BucketField,
    //   payload
    // );
    if (getfromapidata.length > 0) {
      const getapi = payload_config.get_api_mapping.filter(
        (item) => item.action_name == getfromapidata[0].getdatafromapi
      );
      const apiurl = geturl(getapi[0].api_url, payload);

      const regex = /{{(.*?)}}/g;
      let match = regex.exec(getapi[0].getoutputdata);

      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
        },
      };

      const response = await customFetch(
        process.env.REACT_APP_URL + "/" + apiurl,
        requestOptions
      );
      const namesMap = {};
      if (response.status == 200) {
        const data = await response.json();
        console.log("-data-", data);
        console.log("apibucketfield", match[1]);
        //payload[getfromapidata[0].BucketField] = data[match[1]];
        namesMap[getfromapidata[0].BucketField] = data[match[1]];
      } else {
        namesMap[getfromapidata[0].BucketField] = " ";
      }
      setNames(namesMap);
    }
    //const uniqueAddedBy = [...new Set(this.state.payload_config.field_mapping.map(item => item.added_by))];
  };
  const getDataIds = () => {
    // payload_config.field_mapping.map((cell, tdindex) => {
    //   handlemassCheckboxChange(payload_config.payload_type == "M" ? payload._id.$oid : payload["key"]);
    // });
  };
  useEffect(() => {
    setDisabled(edit);
    let _updatedPayload = {
      ...payload,
    };

    setUpdatedPayload(_updatedPayload);
    checkApicall();
  }, [edit]);

  const checkApicall = () => {
    const filteredList = payload_config.field_mapping.filter(
      (item) => item.addapi === "1"
    );
    console.log("Reasonlist--1", Reasonlist);

    let dropdown_id = "";
    let item_value = "";
    let fieldname = "";
    if (filteredList.length > 0) {
      item_value = converttoarray(filteredList[0].BucketField, updatedPayload);
      dropdown_id = filteredList[0].dropdownlist;
      fieldname = filteredList[0].BucketField;
    }
    if (dropdown_id && item_value) {
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "payloadview",
          user_id: localStorage.getItem("in_userid"),
        },
      };

      customFetch(
        process.env.REACT_APP_URL +
          "/get/dropdown/item/master/data/" +
          dropdown_id +
          "/" +
          item_value,
        requestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log("json.next_possible_values", json);
          let resultObject = "";
          if (json.next_possible_values) {
            const filteredArray = Reasonlist[fieldname].filter((item) =>
              json.next_possible_values.includes(item.item_value)
            );

            resultObject = {
              ...Reasonlist,
              [fieldname]: filteredArray,
            };
            console.log("filteredArray->", resultObject);
          } else {
            resultObject = {
              ...Reasonlist,
              [fieldname]: "",
            };
          }
          setupdateReasonlist(resultObject);
          //setmand_fld(json.mand_fld_for_this_status);
          //setisBlock(false);
        });
    } else {
      setupdateReasonlist(Reasonlist);
    }
  };
  useEffect(() => {
    if (DeleteBlock == 1 && change_blockdelete) {
      handlePayloadChange("", DeleteBucketField);
      let loaddata = 1;
      delete_payloadkey(
        payload_config["bucket_name"],
        payload["key"],
        loaddata
      );
    }
  }, [DeleteBlock]);

  useEffect(() => {
    if (updateAmount == 1 && selectedProduct != "") {
      let splitproductdata = selectedProduct.split("~");

      handlePayloadChange(parseInt(splitproductdata[0]), productidtoupdate);
      handlePayloadChange(parseInt(splitproductdata[1]), fieldtoupdate);
      handlePayloadChange(parseInt(splitproductdata[2]), tenure);
    }
    if (
      updateAmount == 1 &&
      selectedProduct != "" &&
      Object.keys(change_data).length != 0
    ) {
      console.log("change_data", change_data);
      let loaddata = 1;
      editHandler(payload_config["bucket_name"], payload["key"], loaddata);
      setupdateAmount(0);
      setselectedProduct();
    }
  }, [updateAmount, change_data]);

  const convertToInputType = (value, inputType) => {
    switch (inputType) {
      case "text":
        return String(value);
      case "number":
        return Number(value);
      case "int32":
        return parseInt(value, 10) | 0;
      // Add more cases for other input types as needed
      default:
        return value;
    }
  };

  const handlePayloadChange = (inputValue, bucket_field) => {
    //if (payload_config.payload_type == "B") {
    //console.log("bucket_field", payload.field_mapping);
    console.log("bucket_field", updatedPayload);
    let _updatedPayload = {
      ...updatedPayload,
    };

    let value = convertToInputType(
      inputValue,
      typeof converttoarray(bucket_field, updatedPayload)
    );
    let splitdata_bucket_field = bucket_field.split(".");
    splitdata_bucket_field.reduce((obj, key, i) => {
      if (i === splitdata_bucket_field.length - 1) {
        //obj[key] = JSON.parse(value) ? JSON.parse(value) : value;
        obj[key] = value;
      }
      return obj[key];
    }, _updatedPayload);
    setUpdatedPayload({ ..._updatedPayload });
    setchange_data((prevData) => ({ ...prevData, [bucket_field]: value }));
    checkReason(_updatedPayload);
    // call below function if save mongo return error
    //checkmand_fld(_updatedPayload);
  };
  const checkmand_fld = (payload_data, req_fld) => {
    if (req_fld) {
      // set default config
      console.log("payload_config.field_mapping", payload_config.field_mapping);
      //console.log("defaultpayload_config", defaultpayload_config.field_mapping);
      const updatedDataDefault = payload_config.field_mapping.map((item) => {
        const matchingItem = defaultpayload_config.field_mapping.find(
          (item1) => item1.BucketField === item.BucketField
        );

        if (matchingItem) {
          // If a matching item is found in data1, update the isRequired value
          return {
            ...item,
            isRequired: matchingItem.isRequired,
          };
        }

        // If no matching item is found, keep the original item
        return item;
      });

      // const filteredList = payload_config.field_mapping.filter(
      //   (item) => item.addapi === "1"
      // );

      // let item_value = filteredList[0].BucketField;

      // let keyToFilter = converttoarray(item_value, payload_data);

      //const filteredData = mand_fld[keyToFilter];
      const filteredData = req_fld;
      if (filteredData) {
        const updatedData = updatedDataDefault.map((item) => {
          if (filteredData.includes(item.BucketField)) {
            item.isRequired = "1";
          }
          return item;
        });

        // const updatedData = updatedDataDefault.filter(
        //   (item) => item.isRequired === "1"
        // );
        console.log("updatedData", updatedData);
        setnewfield_mapping(updatedData);
        validatepayloaddata(updatedData);
      }
    }
  };

  const validatepayloaddata = (updatedData = []) => {
    setPayloadRequired();
    let isValid = true;

    let PayloadRequired = [];
    let mapdata = updatedData ? updatedData : payload_config.field_mapping;
    console.log("mapdata--", mapdata);
    mapdata.map((field) => {
      if (field.isRequired === "1") {
        PayloadRequired.push(field);
      }
    });

    console.log("PayloadRequired", updatedData);
    const list = [...PayloadRequired];
    //console.log("list", list);
    PayloadRequired.map((bucket_field, index) => {
      //let splitdata_bucket_field = bucket_field.field.split(".");
      let splitdata = bucket_field.BucketField.split(".");
      let getvalue = splitdata.reduce((obj, key) => obj[key], updatedPayload);
      if (!getvalue) {
        isValid = false;
        list[index]["isError"] = "1";
      } else {
        list[index]["isError"] = "0";
      }
    });

    const isError1Fields = list
      .filter((item) => item.isError === "1")
      .map((item) => item.fieldname)
      .join(", ");

    const hasErrors = isError1Fields.length > 0;
    if (hasErrors) {
      console.log("list->", isError1Fields);
      setisErrorValue(isError1Fields);
      setisError(true);
    } else {
      setisError(false);
    }
    setPayloadRequired(list);
    console.log("PayloadRequired", PayloadRequired);
    return isValid;
  };

  const checkReason = (payload_data) => {
    if (payload_data.advance > 0) {
      const filteredData = Reasonlist["advance"].filter(
        (item) => item.item_value == payload_data.advance
      );
      if (filteredData[0].target_queue != "") {
        let _updatedPayload = {
          ...payload_data,
        };
        let splitqueue = filteredData[0].target_queue.split("|");
        splitqueue.map((splitdata) => {
          let splitpair = splitdata.split("=");
          let bucket_key = splitpair[0].trim();
          let splitdata_bucket_field = bucket_key.split("."); //payload_config[bucket_key].split(".");
          splitdata_bucket_field.reduce((obj, key, i) => {
            if (i === splitdata_bucket_field.length - 1) {
              //obj[key] = JSON.parse(value) ? JSON.parse(value) : value;
              obj[key] = splitpair[1].trim();
            }
            return obj[key];
          }, _updatedPayload);
          console.log("_updatedPayload-->", _updatedPayload);
          setUpdatedPayload({ ..._updatedPayload });
        });
      }
    }
  };

  useEffect(() => {
    if (isModalFormUpdated) {
      // Call actionHandler here based on the updated ModalForm
      setIsModalFormUpdated(false);
      checkonsaveapi();
    }
  }, [ModalForm, replacedFormrow, isModalFormUpdated]);
  const checkonsaveapi = async () => {
    const action = payload_config.action_mapping.filter((item) => {
      return item.action_event == "onsave";
    })[0];
    console.log("actionn", action);
    console.log("ModalFormm", ModalForm);
    const onsave = true;
    if (action) {
      if (ModalForm || replacedFormrow) {
        if (ActionFormType == "formdata") {
          actionHandler(action.id, ModalForm, ActionFormType);
        }
        if (ActionFormType == "formrow") {
          actionHandler(action.id, replacedFormrow, ActionFormType);
        }
      } else {
        generate_modaldata(action.id, updatedPayload, onsave);
      }
    }
  };
  const delete_payloadkey = async (bucket_name, payload_key, loaddata = 0) => {
    if (payload_config.payload_type == "M") {
      let dbname, collectionname;
      if (payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = payload_config.db_name;
      }

      if (payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = payload_config.collection_name;
      }
      console.log("payload_key->", payload_key);
      console.log("payload->", payload);
      let objectid = payload._id.$oid;
      let upd_data;
      if (DeleteBlock == 1) {
        upd_data = change_blockdelete;
      } else {
        upd_data = change_data;
      }
      console.log("After state update:", upd_data);
      const response = await delete_mongofield(
        api_token,
        dbname,
        collectionname,
        objectid,
        upd_data
      );

      if (response.status == 1) {
        if (DeleteBlock == 1) {
          setDeleteBlock(0);
        }
        setisblockDataInput(false);
        checkonsaveapi();
        if (loaddata == 1) {
          getPayloads();
        }
        Modal.success({
          content: <pre id="json">Payload updated successfully!</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">Error updating the payload</pre>,
          title: "Error",
        });
        return false;
      }
    }
  };
  const editHandler = async (bucket_name, payload_key, loaddata = 0) => {
    if (payload_config.payload_type == "M") {
      let dbname, collectionname;
      if (payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = payload_config.db_name;
      }

      if (payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = payload_config.collection_name;
      }
      console.log("payload_key->", payload_key);
      console.log("payload->", payload);
      let objectid = payload._id.$oid;
      let upd_data;
      if (DeleteBlock == 1) {
        upd_data = change_blockdelete;
      } else {
        upd_data = change_data;
      }
      console.log("After state update:", upd_data);
      if (payload_config.setupdated_at && payload_config.setupdated_at) {
        upd_data = addupdateddata(upd_data);
      }

      const bucketFields = payload_config.field_mapping.map(
        (item) => item.BucketField
      );
      const addDataBeforeField = payload_config.field_mapping.find(
        (item) => item.add_data_before === "1"
      );

      // Initialize the result object
      const result = { add_data_before: {} };

      // Flag to check if any key matched
      let anyKeyMatched = false;

      // Iterate over upd_data keys
      for (const key in upd_data) {
        if (bucketFields.includes(key)) {
          if (addDataBeforeField && key === addDataBeforeField.BucketField) {
            result["add_data_before"][key] = upd_data[key];
            anyKeyMatched = true;
          } else {
            result[key] = upd_data[key];
          }
        } else {
          result[key] = upd_data[key];
        }
      }

      // If no keys matched, ensure add_data_before is an empty object
      if (!anyKeyMatched) {
        result["add_data_before"] = {};
      }
      this.setState({ isspin: true });
      const response = await update_mongopayload(
        api_token,
        dbname,
        collectionname,
        objectid,
        result
      );

      if (response.status == 0) {
        console.log("updatedPayload", updatedPayload);
        this.setState({ isspin: false });
        checkmand_fld(updatedPayload, response.fields);
      } else if (response.status == 1) {
        this.setState({ isspin: false });
        if (DeleteBlock == 1) {
          setDeleteBlock(0);
        }
        setisblockDataInput(false);
        checkonsaveapi();
        if (loaddata == 1) {
          getPayloads();
        }
        Modal.success({
          content: <pre id="json">Payload updated successfully!</pre>,
          title: "Success",
        });
        return true;
      } else {
        this.setState({ isspin: false });
        Modal.error({
          content: <pre id="json">{response.message}</pre>,
          title: "Error",
        });
        return false;
      }
    }
    if (payload_config.payload_type == "B") {
      console.log("finalData", payload_key);
      const response = await update_payload(
        api_token,
        bucket_name,
        payload_key,
        updatedPayload
      );

      if (response.status == 1) {
        Modal.success({
          content: <pre id="json">Payload updated successfully!</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">Error updating the payload</pre>,
          title: "Error",
        });
        return false;
      }
    }
  };

  const actionHandler = async (action_id, payload_data, ActionFormType) => {
    let payloadkey = payload.key;
    console.log("payload_config-->", payload_config);
    try {
      setDisabled(true);
      const action = payload_config.action_mapping.filter((item) => {
        return item.id == action_id;
      })[0];
      let URL;
      console.log("action", action);
      // if (payload_config.payload_type == "M") {
      //   URL = process.env.REACT_APP_PYTHON_URL;
      // }
      // if (payload_config.payload_type == "B") {
      //   URL = process.env.REACT_APP_URL;
      // }
      if (action.url_type == "B") {
        URL = action.api_url;
      } else {
        URL = process.env.REACT_APP_URL + "/" + action.api_url;
      }
      const url = URL;
      let payload_input = null;
      let method = null;
      if (payload_data) {
        if (ActionFormType == "formdata") {
          const form = new FormData();
          payload_data.forEach((field) => {
            form.append(field.field, field.value);
          });
          if (payload_config.payload_type == "B") {
            form.append("doc_id", payloadkey);
          }
          payload_input = form;
        }
        if (ActionFormType == "formrow") {
          payload_input = payload_data;
        }
        method = "POST";
      }
      let generate_Id = generateId(10);
      let requestOptions = "";
      if (ActionFormType == "formdata") {
        requestOptions = {
          method: method,
          headers: {
            "api-token": api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "",
            user_id: localStorage.getItem("in_userid"),
          },
          body: payload_input,
        };
      }
      if (ActionFormType == "formrow") {
        requestOptions = {
          method: method,
          headers: {
            "api-token": api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "",
            user_id: localStorage.getItem("in_userid"),
            "Content-Type": "application/json",
          },
          body: payload_input,
        };
      }

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      setDisabled(false);
      getPayloads();
    }
  };
  const generate_modaldata = async (
    action_id,
    payload_data,
    onsave = false
  ) => {
    console.log("action_idd", action_id);
    console.log("payload_dataa", payload_data);
    const action = payload_config.action_mapping.filter((item) => {
      return item.id == action_id;
    })[0];
    if (onsave) {
      setIsModalFormUpdated(true);
    }
    const url = action.api_url;
    // let payload = null;
    // let method = null;
    if (action["formdata"]) {
      setActionFormType("formdata");
      let form = [];
      for (var k = 0; k < payload_config.field_mapping.length; k++) {
        for (var l = 0; l < action.formdata.length; l++) {
          var exists = false;
          if (
            payload_config.field_mapping[k].fieldname ===
            action.formdata[l].formdatavalue
          ) {
            console.log(
              "action->",
              payload_config.field_mapping[k].BucketField
            );
            console.log("payload_data->", payload_data);
            let splitdaTa = payload_config.field_mapping[k].BucketField.split(
              "."
            );
            let searchdata = "";
            // if (splitdaTa.length > 0) {
            //   for (let index = 0; index < splitdaTa.length; index++) {
            //     searchdata += '["' + splitdaTa[index] + '"]';
            //   }
            // }
            let result = payload_data;
            for (let key of splitdaTa) {
              result = result[key];
            }
            //console.log("field", action.formdata[l].formdatakey);
            //console.log("value", action.formdata);

            form.push({
              field: action.formdata[l].formdatakey,
              //value: payload_data[action.formdata[l].formdatakey],
              value: result,
              isRequired: payload_config.field_mapping[k].isRequired,
              datatype: payload_config.field_mapping[k].datatype,
              isEdit: payload_config.field_mapping[k].isEdit,
              fieldlabel: payload_config.field_mapping[k].fieldname,
              isError: "",
            });
            break;
          }
        }
      }
      console.log("form-->", form);
      setModalForm(form);
      // payload = form;
      // method = "POST";
    }
    if (action["formrow"]) {
      setActionFormType("formrow");
      const extractVariablesFromObject = (obj) => {
        if (typeof obj === "object" && obj !== null) {
          const result = {};
          for (const key in obj) {
            result[key] = extractVariablesFromObject(obj[key]);
          }
          return result;
        } else if (typeof obj === "string") {
          return extractVariables(obj);
        } else {
          return obj;
        }
      };
      console.log("payload_config.field_mapping", payload_config.field_mapping);
      // Function to extract variables from a string
      const extractVariables = (str) => {
        const regex = /{{(.*?)}}/g;
        const matches = str.match(regex);
        return matches ? matches.map((match) => match.slice(2, -2)) : [str];
      };

      // Extract variables from the input object
      const result = extractVariablesFromObject(action["formrow"]);
      let replacementValues = {};
      const checkNullData = (key_name) => {
        console.log("key_name", key_name);
        if (key_name == "user_id") {
          return localStorage.getItem("in_userid");
        }
        if (key_name == "payload") {
          return JSON.stringify(updatedPayload);
        }
      };

      result.map((cell) => {
        replacementValues[cell] = converttoarray(cell, payload)
          ? converttoarray(cell, payload)
          : checkNullData(cell);
      });

      const extractVariablesFroReplace = (obj, replacementValues) => {
        if (typeof obj === "object" && obj !== null) {
          const result = {};
          for (const key in obj) {
            result[key] = extractVariablesFroReplace(
              obj[key],
              replacementValues
            );
          }
          return result;
        } else if (typeof obj === "string") {
          return replaceVariables(obj, replacementValues);
        } else {
          return obj;
        }
      };

      const replaceVariables = (str, replacementValues) => {
        const regex = /{{(.*?)}}/g;
        const replacedStr = str.replace(regex, (match, variable) => {
          return replacementValues[variable] || match;
        });
        return replacedStr;
      };

      // Example usage:
      console.log("Before:", action["formrow"]);
      let replacedFormrow;

      replacedFormrow = extractVariablesFroReplace(
        action["formrow"],
        replacementValues
      );
      const keysWithNullValues = Object.keys(replacementValues).filter(
        (key) =>
          replacementValues[key] === null || replacementValues[key] === ""
      );
      console.log("hasNullValue", keysWithNullValues);
      setreplacementValues(keysWithNullValues);

      console.log("After:", replacedFormrow);

      //console.log("replacementValues", replacementValues);
      setreplacedFormrow(replacedFormrow);
    }
  };
  const converttoarray = (key, data) => {
    const keys = key.split(/[.:]/);
    // Initialize a variable to store the current data object
    let currentData = data;

    // Loop through each part of the key
    for (const k of keys) {
      console.log("keys-->", k);
      console.log("keys1-->", currentData);
      // Check if the currentData is an object and has the key
      if (currentData && currentData.hasOwnProperty(k)) {
        currentData = currentData[k]; // Update currentData to the nested object
      } else {
        return ""; // Return a default value if any part of the key is not present
      }
    }
    console.log("key->", key + "--" + currentData);
    return currentData;
  };
  const viewFamily = (hhid) => {
    const cbResponseArray = payload.data.cb_response;

    // Filter ConsumerName and ReferenceNumber based on household_id
    const filteredData = cbResponseArray
      .filter((response) => payload.data.household_id === hhid)
      .map((response) => ({
        ConsumerName: response.ConsumerName,
        ReferenceNumber: response.ReferenceNumber,
      }));

    // Display the result
    setfamilyData(filteredData);
    setisFamily(true);
  };
  const viewImage = (path) => {
    const formdata = new FormData();
    formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
    customFetch(process.env.REACT_APP_URL + "/get/public/url", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setImageView(json.public_url);
        setisImageView(true);
      });
    //const data = await response.blob();
  };

  const showdata = async (apiname, tabledata) => {
    let getapiconfig = JSON.parse(payload_config.get_api_mapping);
    let findapi = getapiconfig.find((item) => item.action_name == apiname);

    let form = [];
    for (var k = 0; k < payload_config.field_mapping.length; k++) {
      for (var l = 0; l < findapi.formdata.length; l++) {
        var exists = false;
        if (
          payload_config.field_mapping[k].fieldname ===
          findapi.formdata[l].formdatavalue
        ) {
          form.push({
            field: findapi.formdata[l].formdatakey,
            value: payload[payload_config.field_mapping[k].BucketField],
          });
          break;
        }
      }
    }
    for (var l = 0; l < findapi.formdata.length; l++) {
      if (
        findapi.formdata[l].formdatavalue == "" ||
        findapi.formdata[l].formdatavalue == null ||
        findapi.formdata[l].formdatavalue == undefined
      ) {
        form.push({
          field: findapi.formdata[l].formdatakey,
          value: findapi.formdata[l].defaultvalue,
        });
      }
    }
    const formdata = new FormData();
    form.map((fomdata) => {
      formdata.append(fomdata.field, fomdata.value);
    });
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/" + findapi.api_url,
      requestOptions
    );
    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    setShowData(data);
    setisShowData(true);
  };
  const handleCancelimage = () => {
    setisImageView(false);
  };
  const handleCancelfamily = () => {
    setisFamily(false);
  };
  const handleCanceljson = () => {
    setisPayloadView(false);
  };
  const handleCancelblock = () => {
    setisBlock(false);
  };
  const handleCanceldata = () => {
    setisShowData(false);
  };
  const handleCancelgroupid = () => {
    setisgroupid(false);
  };
  // const handleRowClick = (rowData) => {
  //   console.log("rowData", payload);
  //   const subinput = getGroupForEntry(payload);
  //   setExpandedRow(subinput);
  //   if (isMobileView) {
  //     console.log("isMobileView", isMobileView);

  //     setisExprow(true);
  //   }
  // };
  const Dropdownlist = (val) => {
    console.log("valll--1", val);
    let dropdown_data = "";
    if (val.bucketfield.dropDownOptions) {
      dropdown_data = val.bucketfield.dropDownOptions;
    }
    let bucket_field = val.bucketfield.BucketField.replace(/\./g, "_");
    let isedits = updateReasonlist && val.isedit == true && (
      <select
        className="form-control"
        defaultValue={val.value}
        //value={ReasonData}
        style={{
          width: 120,
        }}
        onChange={(event) => {
          handlePayloadChange(parseInt(event.target.value), val.bucketfield);
          //setReasonData(event.target.value);
        }}
      >
        <option value="">Select</option>
        {updateReasonlist[bucket_field] &&
          updateReasonlist[bucket_field].map((list) => (
            <option value={list.item_value}>
              {dropdown_data == "V"
                ? list.item_value
                : dropdown_data == "L"
                ? list.item_name
                : dropdown_data == "B"
                ? list.item_value + " - " + list.item_name
                : list.item_name}
            </option>
          ))}
      </select>
    );
    if (val.isedit == false) {
      // let iseditfalse;
      // iseditfalse = Reasonlist.map(
      //   (list) => list.item_value == val.value && list.item_name
      // );
      console.log("Reasonlist11-1", updateReasonlist);
      if (updateReasonlist && updateReasonlist[bucket_field]) {
        const matchingItem = updateReasonlist[bucket_field].find(
          (item) => item.item_value == val.value
        );

        // Check if a matching item was found if not then show value
        if (matchingItem) {
          const itemName = matchingItem.item_name;
          isedits = itemName;
        } else {
          isedits = val.value;
        }
      } else {
        isedits = "Select";
      }
    }
    return isedits;
  };
  const downloadfile = async (path) => {
    setisspin(true);
    // try {
    //   const storage = getStorage(); // Initialize Firebase Storage
    //   const fileRef = ref(storage, path);

    //   // Get the download URL for the file
    //   const downloadUrl = await getDownloadURL(fileRef);
    //   // Create a temporary link for the download
    //   const link = document.createElement("a");
    //   link.href = downloadUrl;
    //   link.setAttribute("download", path);
    //   document.body.appendChild(link);

    //   // Simulate a click on the link to trigger the download
    //   link.click();

    //   // Remove the temporary link from the document
    //   document.body.removeChild(link);
    // } catch (error) {
    //   console.error("Error downloading file:", error);
    // }
    const formdata = new FormData();
    formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "PaylodView",
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    customFetch(
      process.env.REACT_APP_URL + "/download/files/bucket",
      requestOptions
    )
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }

        res.blob().then((img) => {
          //URL.createObjectURL(img)
          const url = window.URL.createObjectURL(img);
          //console.log(URL.createObjectURL(img))
          // this.setState({
          //   viewImg: URL.createObjectURL(img),
          //   isOpen: true,
          // });
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", path);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
        setisspin(false);
      })
      .catch((error) => console.log("error", error));
  };

  const showproductdata = (amount, fieldto_update, productid, tenure) => {
    setfieldtoupdate(fieldto_update);
    setproductidtoupdate(productid);
    settenure(tenure);
    setselectedProduct(
      (converttoarray(productid, payload) || "") +
        "~" +
        (converttoarray(fieldto_update, payload) || "") +
        "~" +
        (converttoarray(tenure, payload) || "")
    );
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "payloadview",
        user_id: localStorage.getItem("in_userid"),
      },
    };
    const params = {
      max_amount: amount,
      household_id: converttoarray("household_id", payload),
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL + "/partner/get/dcb/products?" + queryParams,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status == 1) {
          setproductlist(json.data);
        } else {
          setproductlist([]);
          seterrorMsg(json.message);
        }
        setisProductList(true);
        console.log("jsondata->", json);
      });
  };
  const showblockdata = (block, fieldname, BucketField) => {
    // const groupedData = {};

    // for (const key in block) {
    //   if (block.hasOwnProperty(key)) {
    //     const field = block[key];
    //     const parts = key.split(".");
    //     const group = parts.slice(0, -1).join(".") || fieldname; // Set to "aaa" if group is not found

    //     if (!groupedData[group]) {
    //       groupedData[group] = [];
    //     }

    //     groupedData[group].push({ BucketField: key, value: field });
    //   }
    // }

    setblockData(block);
    setDeleteBucketField(BucketField);
    setisBlock(true);
  };
  const deleteblockdelete = (deletepath) => {
    console.log("deleteblockpath:", deletepath);

    setchange_blockdelete({
      [deletepath]: "",
    });
    setblockData();
    setisBlock(false);
    setDeleteBlock(1);
    console.log("Before State Update:", blockData);
    // setTimeout(() => {

    // }, 1000);
    //console.log("DeleteBucketField", change_data);
  };

  const chkpayloadrequired = () => {
    // below condition for check if status U then user can't update field
    if (updateReasonlist["status"]) {
      if (converttoarray("status", payload)) {
        const hasItemWithValueTwoAndStatusU = updateReasonlist["status"].some(
          (item) =>
            item.item_value === converttoarray("status", payload) &&
            item.status === "U"
        );
        // If such an item exists, trigger an alert
        if (hasItemWithValueTwoAndStatusU) {
          setEdit(false);
          alert("This Entry Can Not Edit");
        }
        console.log("status==>", updateReasonlist["status"]);
      }
    }
    console.log("chkpayloadrequired", payload_config.field_mapping);
    ////console.log("payload_config.field_mapping", payload_config.field_mapping);
    let Editfields = [];
    payload_config.field_mapping.map((field) => {
      if (field.isEdit === "1") {
        Editfields.push({
          fieldName: field.fieldname,
          field: field.BucketField,
          isError: "",
        });
      }
    });
    console.log("Editfields", Editfields);
    const updatedDefaultData = payload_config.field_mapping.map((item) => {
      const matchingItem = defaultpayload_config.field_mapping.find(
        (item1) => item1.BucketField === item.BucketField
      );
      if (matchingItem) {
        // If a matching item is found in data1, update the isRequired value
        return {
          ...item,
          isEdit: matchingItem.isEdit,
        };
      }
      // If no matching item is found, keep the original item
      return item;
    });
    let DisableEditfields = [];
    payload_config.field_mapping.map((field) => {
      if (field.isEditDisable === "1") {
        // if (field.datatype === "dropdownlist") {
        //   let oldvalue = updatedPayload[field.BucketField];
        //   let row_columnName = this.state.Reasonlist[field.BucketField].find(
        //     (item) => item.item_value === oldvalue
        //   );
        //   if (row_columnName !== undefined) {
        //     console.log("row_columnName", row_columnName.item_name);
        //     updatedPayload[field.BucketField] = row_columnName.item_name;
        //   }
        // }

        const matches = field.DisableOnFieldValue.match(/(\w+)(=|>|<)(\w+)/);
        if (matches) {
          const [, key, operator, value] = matches;
          let disablefield = [];
          DisableEditfields.push({
            fieldName: field.fieldname,
            field: field.BucketField,
            isError: "",
            DisableOnField: key,
            DisableOnFieldValue: value,
            DisableOperator: operator,
          });
          Editfields.forEach((dataItem) => {
            const matchingData1 = DisableEditfields.find(
              (data1Item) => data1Item.field === dataItem.field
            );
            if (matchingData1) {
              const {
                field,
                DisableOnField,
                DisableOnFieldValue,
                DisableOperator,
              } = matchingData1;
              // Assuming data2 is an object, iterate through its keys
              for (const key in updatedPayload) {
                let expression = `${updatedPayload[key]} ${DisableOperator} ${DisableOnFieldValue}`;
                console.log("expression", expression);
                try {
                  // Evaluate the expression using a safer alternative like Function constructor
                  let evaluateExpression = new Function(`return ${expression}`);
                  let result = evaluateExpression();
                  // 'result' will be true or false based on the evaluation
                  console.log("result", result);
                } catch (error) {
                  console.error("Error evaluating expression:", error);
                }
                if (key == DisableOnField && eval(expression)) {
                  disablefield.push(field);
                  // Do something if the condition is met
                }
              }
            }
          });
          // set isEdit = 0 if any field is mark as disable on specific status
          updatedDefaultData.forEach((item) => {
            if (disablefield.includes(item.BucketField)) {
              item.isEdit = "0";
            }
          });
          setnewfieldEdit_mapping(updatedDefaultData);
        } else {
          console.log("No matches found.");
        }
      }
    });
    console.log("DisableEditfields", DisableEditfields);
    console.log("DisablePayload", updatedPayload);
  };
  const extractstatedataVariablesFromObject = (obj) => {
    if (typeof obj === "object" && obj !== null) {
      const result = {};
      for (const key in obj) {
        result[key] = extractstatedataVariablesFromObject(obj[key]);
      }
      return result;
    } else if (typeof obj === "string") {
      return extractVariables(obj);
    } else {
      return obj;
    }
  };
  const extractVariables = (str) => {
    const regex = /{{(.*?)}}/g;
    const matches = str.match(regex);
    return matches ? matches.map((match) => match.slice(2, -2)) : [str];
  };
  const getstatedata = (data) => {
    const result = extractstatedataVariablesFromObject(data);
    let replacementValues = {};
    result.map((cell) => {
      console.log("celll", cell);
      replacementValues[cell] = converttoarray(cell, payload);
    });
    // Example usage:
    console.log("Before:", replacementValues);
    let replacedFormrow;

    replacedFormrow = extractstatedataVariablesFroReplace(
      data,
      replacementValues
    );

    console.log("After:", replacedFormrow);
    return replacedFormrow;
  };

  const extractstatedataVariablesFroReplace = (obj, replacementValues) => {
    if (typeof obj === "object" && obj !== null) {
      const result = {};
      for (const key in obj) {
        result[key] = extractstatedataVariablesFroReplace(
          obj[key],
          replacementValues
        );
      }
      return result;
    } else if (typeof obj === "string") {
      return replacestatedataVariables(obj, replacementValues);
    } else {
      return obj;
    }
  };

  const replacestatedataVariables = (str, replacementValues) => {
    const regex = /{{(.*?)}}/g;
    const replacedStr = str.replace(regex, (match, variable) => {
      return replacementValues[variable] || match;
    });
    return replacedStr;
  };

  const checkredirect = (RedirectData, RedirectCondition, BucketField) => {
    console.log("RedirectData", RedirectData);
    console.log("RedirectCondition", RedirectCondition);
    console.log("BucketField", BucketField);
    // Define a regular function
    // Extracting key, operator, and value from RedirectCondition
    const conditions = RedirectCondition.split(",");
    console.log("conditions", conditions);
    let allConditionsMatch = true; // For AND logic

    conditions.forEach((condition) => {
      const matches = condition.match(/([\w.]+)(==|!=|>|<)('.*'|\w+)/);
      if (matches) {
        const [, key, operator, value] = matches;
        let conditionResult = false;
        let keyValue, valuedata;

        // Get the key value from the payload
        keyValue = converttoarray(key, payload);
        valuedata = value.replace(/'/g, ""); // Remove quotes if present

        // Convert to number if both keyValue and valuedata are numeric
        if (!isNaN(keyValue) && !isNaN(valuedata)) {
          keyValue = Number(keyValue);
          valuedata = Number(valuedata);
        }

        console.log(`Condition: ${key} ${operator} ${value}`);
        console.log(`Evaluating: ${keyValue} ${operator} ${valuedata}`);

        switch (operator) {
          case "!=":
            conditionResult = keyValue != valuedata;
            break;
          case "==":
            conditionResult = keyValue == valuedata;
            break;
          case ">":
            conditionResult = keyValue > valuedata;
            break;
          case "<":
            conditionResult = keyValue < valuedata;
            break;
          default:
            console.log("Unsupported operator");
            conditionResult = false;
        }

        // For AND logic, all conditions must be true
        allConditionsMatch = allConditionsMatch && conditionResult;
        console.log(`Condition result: ${conditionResult}`);
      } else {
        console.log("Invalid condition format");
        allConditionsMatch = false;
      }
    });

    // Set RedirectConditionWork based on the required logic
    let RedirectConditionWOrk = allConditionsMatch ? 1 : 0;
    console.log("RedirectConditionWOrk:", RedirectConditionWOrk);

    // Checking condition
    if (RedirectConditionWOrk == 1) {
      console.log("chkcondition", RedirectConditionWOrk);
      // Extracting object from RedirectData
      let obj = RedirectData.toString()
        .split("requiredata:")[1]
        .split(",");
      // Getting state data
      const results = obj.map((obj, key) => {
        //const key = Object.keys(obj)[0];
        const statedata = getstatedata(obj);
        console.log("obj0", statedata);
        return { [key]: JSON.parse(statedata) }; // Return the updated object
      });

      let statedata = results; //getstatedata(obj);
      let urldata;
      let match = RedirectData.match(/path:'(.*?)'/);
      if (match && match[1]) {
        urldata = match[1];
      }
      let urlpath = getstatedata(urldata);
      console.log("urldata", urlpath);
      const convertedData = urlpath.replace(/{|}|"/g, "");
      console.log("convertedData", convertedData);

      if (statedata) {
        let parsedData;

        try {
          // Parsing state data
          console.log("parsedData", statedata);
          parsedData = statedata; //JSON.parse(statedata);
        } catch (error) {
          console.error("Error parsing data:", error);
          return null;
        }

        // Check if parsedData is an array
        let modifiedArray = {};
        if (Array.isArray(parsedData)) {
          parsedData.forEach((obj) => {
            for (let key in obj) {
              const innerArray = obj[key];
              innerArray.forEach((innerObj) => {
                for (let innerKey in innerObj) {
                  modifiedArray[innerKey] = innerObj[innerKey];
                }
              });
            }
          });

          console.log("modifiedArray", parsedData);
        }

        const url = new URL(window.location.href);
        const baseUrl = url.origin;
        console.log("urlExists1", baseUrl + "/payload/view"); // true if URL exists, false otherwise

        // Returning JSX
        return (
          <a
            href="javascript:void(0)"
            onClick={() => {
              checkUrlExists(baseUrl + "/" + convertedData).then(
                (urlExists) => {
                  if (urlExists == false) {
                    const state = {
                      api_token: api_token,
                      modifiedArray,
                      // ...parsedData.reduce((acc, item) => {
                      //   const key = Object.keys(item)[0];
                      //   acc[key] = item[key];
                      //   return acc;
                      // }, {}),
                    };

                    props.history.push({
                      pathname: "../" + convertedData,
                      // RedirectData.toString()
                      //   .split("path:")[1]
                      //   .split(",")[0]
                      //   .replace(/'/g, "")
                      //   .trim(),
                      state: state,
                    });
                  }
                }
              );
            }}
          >
            {converttoarray(BucketField, payload)}
          </a>
        );
      }
    }
    // Returning null if condition is not met
    return converttoarray(BucketField, payload);
  };

  const checkfields = (cell) => {
    console.log("celldata", cell);

    if (cell["picklast"] && cell["picklast"] == 1) {
      let updatedString;
      if (cell["pickindex"] && cell["pickindex"] != "") {
        updatedString = cell["BucketField"].replace(
          /:\d+\./,
          ":" + cell["pickindex"] + "."
        );
      } else {
        console.log("BucketField_picklast", cell["BucketField"]);
        const [arrayPath, restPath] = cell["BucketField"].split(":");
        if (payload[arrayPath]) {
          //let number = (getarray - 1);
          updatedString = cell["BucketField"].replace(
            /:\d+\./,
            ":" + parseInt(payload[arrayPath].length - 1) + "."
          );
          console.log("Cel_data-3", updatedString);
        }
      }
      if (updatedString) {
        cell["BucketField"] = updatedString;
      }
    }
    return edit && cell["isEdit"] === "1" ? (
      cell["datatype"] === "productlist" ? (
        <>
          <a
            href="javascript:void(0)"
            disabled={disabled}
            onClick={() => {
              {
                !disabled &&
                  showproductdata(
                    converttoarray(cell["BucketField"], payload),
                    cell["productlist"],
                    cell["productid"],
                    cell["tenure"]
                  );
              }
            }}
          >
            {converttoarray(cell["BucketField"], payload)}
          </a>
        </>
      ) : cell["datatype"] === "block" ? (
        <>
          <a>{cell["fieldname"]}</a>
        </>
      ) : cell["datatype"] === "Downloadfile" ? (
        converttoarray(cell["BucketField"], updatedPayload) ? (
          <button
            className="btn btn-primary"
            onClick={() =>
              downloadfile(converttoarray(cell["BucketField"], updatedPayload))
            }
          >
            Download
          </button>
        ) : (
          "File Not Found"
        )
      ) : cell["datatype"] === "group_id" ? (
        <div className="d-flex">
          <select
            className="form-control"
            style={{
              width: 120,
            }}
            onChange={(event) => {
              handlePayloadChange(event.target.value, cell["BucketField"]);
            }}
            value={converttoarray(cell["BucketField"], updatedPayload)}
          >
            <option value="">Select</option>
            {updategroupid &&
              updategroupid.map((grpid) => (
                <option value={grpid.group_id}>{grpid.group_id}</option>
              ))}
          </select>
          {/* <button
      className="btn btn-primary ml-1"
      onClick={() => adduniqueid()}
    >
      +
    </button> */}
        </div>
      ) : cell["datatype"] === "DCB" ? (
        <TextArea
          autoSize={true}
          readOnly={!edit}
          bordered={edit}
          style={{
            width: 150,
          }}
          maxLength={cell["FieldLength"]}
          value={
            edit
              ? converttoarray(cell["BucketField"], updatedPayload)
              : // :
                // cell["datatype"] === "boolean"
                // ? converttoarray(cell["BucketField"], payload) == 1
                //   ? "Yes"
                //   : "No"
                converttoarray(cell["BucketField"], updatedPayload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], updatedPayload)
                ).replace(/\"/g, "")
          }
          onChange={(event) => {
            handlePayloadChange(event.target.value, cell["BucketField"]);
          }}
        />
      ) : cell["datatype"] === "jsondata" ? (
        <>
          <a>{cell["BucketField"]}</a>
        </>
      ) : cell["datatype"] === "dropdownlist" ? (
        <>
          <Dropdownlist
            value={
              converttoarray(cell["BucketField"], updatedPayload) &&
              JSON.stringify(
                converttoarray(cell["BucketField"], updatedPayload)
              ).replace(/\"/g, "")
            }
            style={{
              width: 120,
            }}
            bucketfield={cell}
            isedit={true}
          />
        </>
      ) : cell["datatype"] === "boolean" ? (
        <select
          className="form-control"
          value={converttoarray(cell["BucketField"], updatedPayload)}
          style={{
            width: 120,
          }}
          onChange={(event) => {
            handlePayloadChange(event.target.value, cell["BucketField"]);
          }}
        >
          <option value="">Select</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      ) : cell["datatype"] === "textbox" ? (
        <TextArea
          autoSize={true}
          readOnly={!edit}
          bordered={edit}
          style={{
            width: 120,
          }}
          maxLength={cell["FieldLength"]}
          value={
            edit
              ? converttoarray(cell["BucketField"], updatedPayload)
              : // :
                // cell["datatype"] === "boolean"
                // ? converttoarray(cell["BucketField"], payload) == 1
                //   ? "Yes"
                //   : "No"
                converttoarray(cell["BucketField"], payload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], payload)
                ).replace(/\"/g, "")
          }
          onChange={(event) => {
            handlePayloadChange(event.target.value, cell["BucketField"]);
          }}
        />
      ) : (
        cell["datatype"] === "date" && (
          <input
            type="date"
            className="form-control"
            value={
              edit
                ? moment(
                    converttoarray(cell["BucketField"], updatedPayload)
                  ).format("YYYY-MM-DD")
                : // :
                  // cell["datatype"] === "boolean"
                  // ? converttoarray(cell["BucketField"], payload) == 1
                  //   ? "Yes"
                  //   : "No"
                  converttoarray(cell["BucketField"], updatedPayload) &&
                  JSON.stringify(
                    converttoarray(cell["BucketField"], updatedPayload)
                  ).replace(/\"/g, "")
            }
            onChange={(event) => {
              handlePayloadChange(event.target.value, cell["BucketField"]);
            }}
          />
        )
      )
    ) : (
      // <TextArea
      //   autoSize
      //   readOnly={true}
      //   bordered={false}
      //   //bordered={edit}
      //   value={JSON.stringify(
      //     converttoarray(cell["BucketField"], payload)
      //   )}
      // />
      <span>
        {cell["datatype"] === "productlist" ? (
          <>
            <a
              href="javascript:void(0)"
              disabled={disabled}
              onClick={() => {
                {
                  !disabled &&
                    showproductdata(
                      converttoarray(cell["BucketField"], payload),
                      cell["productlist"],
                      cell["productid"],
                      cell["tenure"]
                    );
                }
              }}
            >
              {converttoarray(cell["BucketField"], payload)}
            </a>
          </>
        ) : cell["datatype"] === "date" ? (
          converttoarray(cell["BucketField"], updatedPayload) &&
          isUnixTimestamp(
            converttoarray(cell["BucketField"], updatedPayload)
          ) ? (
            moment(
              moment.unix(converttoarray(cell["BucketField"], updatedPayload))
            ).format("YYYY-MM-DD HH:mm:ss")
          ) : isValidDate(
              converttoarray(cell["BucketField"], updatedPayload)
            ) ? (
            moment(converttoarray(cell["BucketField"], updatedPayload)).format(
              "YYYY-MM-DD"
            )
          ) : (
            JSON.stringify(
              converttoarray(cell["BucketField"], updatedPayload)
            ).replace(/\"/g, "")
          )
        ) : cell["datatype"] === "block" ? (
          <>
            <a
              href="javascript:void(0)"
              onClick={() => {
                console.log("call useEffect");
                let _updatedPayload = {
                  ...payload,
                };

                setUpdatedPayload(_updatedPayload);
                showblockdata(
                  converttoarray(cell["BucketField"], updatedPayload),
                  cell["fieldname"],
                  cell["BucketField"]
                );
              }}
            >
              {cell["fieldname"]}
            </a>
          </>
        ) : cell["datatype"] === "Downloadfile" ? (
          converttoarray(cell["BucketField"], payload) ? (
            <button
              className="btn btn-primary"
              onClick={() =>
                downloadfile(converttoarray(cell["BucketField"], payload))
              }
            >
              Download
            </button>
          ) : (
            "File Not Found"
          )
        ) : cell["datatype"] === "DCB" ? (
          <>
            <div
              class="table-elipse"
              data-toggle="collapse"
              data-target={"#" + rowindex}
              onClick={() => {
                handleRowClick(payload);
                updaterowindex(rowindex);
              }}
            >
              <span className="exprowbtn">+</span>
              {converttoarray(cell["BucketField"], payload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], payload)
                ).replace(/\"/g, "")}
            </div>
          </>
        ) : cell["datatype"] === "jsondata" ? (
          <>
            <a
              className="text-primary"
              onClick={() => {
                setisPayloadView(true);
                setPayloadViewData(
                  converttoarray(cell["BucketField"], payload) &&
                    JSON.stringify(converttoarray(cell["BucketField"], payload))
                );
              }}
            >
              {cell["BucketField"].split(".").pop()}
            </a>
          </>
        ) : cell["datatype"] === "boolean" ? (
          converttoarray(cell["BucketField"], payload) &&
          JSON.stringify(converttoarray(cell["BucketField"], payload)).replace(
            /\"/g,
            ""
          ) == 1 ? (
            "Yes"
          ) : (
            "No"
          )
        ) : cell["datatype"] === "dropdownlist" ? (
          <>
            <Dropdownlist
              value={
                converttoarray(cell["BucketField"], payload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], payload)
                ).replace(/\"/g, "")
              }
              bucketfield={cell}
              isedit={false}
            />
          </>
        ) : cell["datatype"] === "image" ? (
          <>
            <button
              className="btn btn-primary"
              onClick={(e) =>
                viewImage(converttoarray(cell["BucketField"], payload))
              }
            >
              View
            </button>
          </>
        ) : cell["isRedirect"] == 1 ? ( // Check if cell["isRedirect"] equals 1
          checkredirect(
            cell["RedirectData"],
            cell["RedirectCondition"],
            cell["BucketField"]
          )
        ) : // : cell["addapi"] == 1 ? (
        //   <>
        //     <span
        //       className="payload-openmodal"
        //       onClick={() =>
        //         showdata(cell["selectapi"], cell["tabledata"])
        //       }
        //     >
        //       {converttoarray(cell["BucketField"], payload) &&
        //         JSON.stringify(
        //           converttoarray(cell["BucketField"], payload)
        //         ).replace(/\"/g, "")}
        //     </span>
        //   </>
        // )

        cell["getfromapi"] && cell["getfromapi"] == 1 ? (
          names[cell.BucketField] || "Loading..."
        ) : (
          converttoarray(cell["BucketField"], payload) &&
          JSON.stringify(converttoarray(cell["BucketField"], payload)).replace(
            /\"/g,
            ""
          )
        )}
        {/* {converttoarray(
    "payload.payment.entity.notes.notes_key_2",
    payload
  )} */}
      </span>
    );
  };
  const isUnixTimestamp = (input) => {
    // Check if input is a number and within Unix timestamp range
    const isNumeric = !isNaN(input);
    const isInt = Number.isInteger(Number(input));
    const isValidRange = Number(input) >= 0 && Number(input) <= 2147483647; // Valid Unix timestamp range in seconds

    return isNumeric && isInt && isValidRange;
  };
  const handleCancel = () => {
    setisOpenModal(false);
  };
  const isValidDate = (input) => {
    // Use Moment.js to check if the input is a valid date
    return moment(input, moment.ISO_8601, true).isValid();
  };
  const validateForm = (ModalForm) => {
    let isValid = true;
    ModalForm.map((field, index) => {
      const list = [...ModalForm];
      if (field.isRequired == "1") {
        if (!field.value) {
          isValid = false;
          list[index]["isError"] = "1";
        }
      } else {
        list[index]["isError"] = "";
      }
      setModalForm(list);
    });
    return isValid;
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...ModalForm];
    list[index]["value"] = value;
    setModalForm(list);
  };
  const handleCancelError = () => {
    setisError(false);
  };

  const checkUrlExists = async (url) => {
    try {
      const response = await customFetch(url);
      if (response.ok) {
        console.log("Response status:", response.status);
        return false; // No 404 error, real URL
      } else {
        console.log("Response status:", response.status);
        return true; // 404 error occurred
      }
    } catch (error) {
      console.error("Error checking for 404:", error);
      return true; // Error occurred, assume 404
    }
  };

  const handleBlur = (event, minmaxlength, fieldname, bucketField) => {
    const minLength = minmaxlength;
    const maxLength = minmaxlength;
    const inputLength = event.target.value.length;

    if (inputLength < minLength) {
      //alert(`${fieldname} must be at least ${minLength} characters long.`);
      setErrorMessage({
        ...ErrorMessage,
        [bucketField]: `${fieldname} must be at least ${minLength} characters long.`,
      });
      setDisableBtn(true);
    } else {
      setErrorMessage("");
      setDisableBtn(false);
    }
  };
  console.log("updatedPayload_change_data", isExprow);
  console.log("updatedPayload_change_data_", selectedrowindex);
  return (
    <>
      <Modal
        open={isImageView}
        onCancel={handleCancelimage}
        footer={[]}
        width="auto"
      >
        {ImageView && <img src={ImageView} className="w-100" />}
      </Modal>
      {/* {view_for=="mobhead" && (
        // <Modal
        //   open={isExprow}
        //   onCancel={() => setisExprow(false)}
        //   footer={[]}
        //   width="auto"
        //   id={selectedrowindex}
        // >
        //   <div id={selectedrowindex} class="cell-1 row-child">
        //     <DetailComponent
        //       detailData={expandedRow}
        //       payload_config={subpayload_config}
        //       api_token={api_token}
        //       effective_designation_id={effective_designation_id}
        //       fetch_onload={true}
        //       storesubdata={storesubdata}
        //       selectedrowindex={selectedrowindex}
        //       filtered_condition={filtered_condition}
        //       sub_data={sub_data||[]}
        //       removedCheckboxes={removedCheckboxes}
        //     />
        //   </div>
        // </Modal>
      )} */}
      <Modal
        open={isProductList}
        onCancel={() => setisProductList(false)}
        width="auto"
        onOk={() => {
          setupdateAmount(1);
          setisProductList(false);
        }}
        okButtonProps={{
          disabled: !selectedProduct || errorMsg,
        }}
      >
        <div className="row">
          {console.log("fieldtoupdate->", productlist)}
          {productlist && productlist.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Amount</th>
                  <th>EMI</th>
                  <th>Tenure</th>
                </tr>
              </thead>
              <tbody>
                {productlist.map((product) => (
                  <tr>
                    <td>
                      <input
                        type="radio"
                        value={
                          product.product_id +
                          "~" +
                          product.amount +
                          "~" +
                          product.tenure
                        }
                        checked={
                          product.product_id +
                            "~" +
                            product.amount +
                            "~" +
                            product.tenure ==
                          selectedProduct
                        }
                        onChange={(e) => setselectedProduct(e.target.value)}
                      />
                    </td>
                    <td>Rs. {product.amount.toLocaleString("en-IN")}</td>
                    <td>Rs. {product.emi.toLocaleString("en-IN")} / Month</td>
                    <td>{product.tenure} Month</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            errorMsg
          )}
        </div>
      </Modal>
      <Modal
        open={isFamily}
        onCancel={handleCancelfamily}
        footer={[]}
        width="auto"
      >
        <table className="table">
          <thead>
            <tr>
              <th>Consumername</th>
              <th>Reference Number</th>
            </tr>
          </thead>
          <tbody>
            {familyData &&
              familyData.map((fdata) => (
                <tr>
                  <td>{fdata.ConsumerName}</td>
                  <td>{fdata.ReferenceNumber}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal>
      <Modal
        open={isPayloadView}
        onCancel={handleCanceljson}
        footer={[]}
        width="auto"
      >
        <div>
          <pre className="showjson">{PayloadViewData}</pre>
        </div>
      </Modal>
      <Modal
        open={isAdd}
        onCancel={() => setisAdd(false)}
        onOk={() => {
          handlePayloadChange(blockData, DeleteBucketField);
          setisAdd(false);
        }}
        okButtonProps={{
          disabled: !blockData,
        }}
        width="auto"
      >
        <div>
          <TextArea
            className="form-control"
            onChange={(e) => {
              try {
                const parsedData = JSON.parse(e.target.value);
                setblockData(parsedData);
                setisblockDataInput(true);
              } catch (error) {
                setblockData("");
                setisblockDataInput(false);
              }
            }}
            value={blockData && JSON.stringify(blockData)}
            rows={15}
          />
        </div>
      </Modal>
      <Modal
        open={isBlock}
        onCancel={handleCancelblock}
        footer={[]}
        width="auto"
      >
        <div>
          {console.log("blockData", blockData)}
          {blockData ? (
            <>
              <ReactJson
                src={blockData}
                onEdit={
                  onEdit
                    ? (e) => {
                        console.log(e);
                        //this.setState({ src: e.updated_src });
                        setUpdatedPayload(e.updated_src);
                      }
                    : false
                }
              />
              {edit && isblockDataInput == false && (
                <div className="text-right">
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteblockdelete(DeleteBucketField)}
                  >
                    <DeleteOutlined />
                  </button>
                </div>
              )}
            </>
          ) : (
            edit && (
              <>
                <button
                  className="btn btn-primary mt-4 mr-4"
                  onClick={() => setisAdd(true)}
                >
                  <FileAddOutlined />
                </button>
              </>
            )
          )}
        </div>
        {/* <div>
          {blockData &&
            Object.entries(blockData).map(([group, fields]) => (
              <div key={group}>
                <h2>{group}</h2>
                <div className="row">
                  {fields.map(({ BucketField, value }) => (
                    <div className="col-md-4" key={BucketField}>
                      <strong>{BucketField}:</strong> {JSON.stringify(value)}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
         */}
      </Modal>
      <Modal
        //title="Title"
        open={isShowData}
        onCancel={handleCanceldata}
        footer={[]}
        width="auto"
      >
        <div className="row">
          {/* {ShowData &&
            ShowData.map((userdata) => (
              <div className="form-group col-md-4">
                {userdata.Branches.Branch}
              </div>
            ))} */}
          {ShowData &&
            ShowData.Branches.map((userdata, index) => {
              let getapiconfig = JSON.parse(payload_config.get_api_mapping);
              return getapiconfig[0].outputdata.map((apiconfig, i) => {
                return (
                  <>
                    <div className="form-group col-md-4">
                      <label className="font-weight-bold">
                        {apiconfig.outputkey}
                      </label>
                      <div>{userdata[apiconfig.outputvalue]}</div>
                    </div>
                  </>
                );
              });
            })}
          {ShowData &&
            ShowData.Branches.map((userdata, index) => {
              let getapiconfig = JSON.parse(payload_config.get_api_mapping);
              let splitrowdata = getapiconfig[0].tabledata[0].tablevalue.split(
                "."
              );
              let splitdata = splitrowdata[0];
              return (
                <>
                  <div className="form-group col-md-12">
                    <table className="table table-hove text-center">
                      <thead>
                        <tr>
                          {getapiconfig[0].tabledata.length > 0 &&
                            getapiconfig[0].tabledata.map((tabledata) => {
                              return <td>{tabledata.tablekey}</td>;
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {getapiconfig[0].tabledata.length > 0 &&
                          getapiconfig[0].tabledata.map((tabledata, i) => {
                            let splitdata = tabledata.tablevalue.split(".");
                            //console.log("i", splitdata);
                            return (
                              <tr>
                                {userdata[splitdata[0]].map(
                                  (lastransaction) => {
                                    return (
                                      <td>{lastransaction[splitdata[1]]}</td>
                                    );
                                  }
                                )}
                              </tr>
                            );
                          })} */}
                        {getapiconfig[0].tabledata.length > 0 &&
                          userdata[splitdata].map((split, i) => {
                            //let splitdata = tabledata.tablevalue.split(".");
                            //console.log("i", splitdata);
                            return (
                              <tr key={i}>
                                {getapiconfig[0].tabledata.map(
                                  (lastransaction) => {
                                    let tablevalue = lastransaction.tablevalue.split(
                                      "."
                                    );
                                    return <td>{split[tablevalue[1]]}</td>;
                                  }
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })}
        </div>
      </Modal>
      <Modal
        //title="Title"
        open={isOpenModal}
        onOk={() => {
          let validation = validateForm(ModalForm);

          if (validation) {
            if (ActionFormType == "formdata") {
              actionHandler(actionValue, ModalForm, ActionFormType);
            }
            if (ActionFormType == "formrow") {
              actionHandler(actionValue, replacedFormrow, ActionFormType);
            }

            setisOpenModal(false);
          }
        }}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={1000}
      >
        <div className="row">
          {ActionFormType == "formdata" &&
            ModalForm &&
            ModalForm.map((field, index) => (
              <>
                <div className="col-3">
                  <label>{field.fieldlabel}</label>
                  {field.datatype === "textbox" ? (
                    <>
                      <input
                        required={field.isRequired == 1}
                        className="form-control"
                        readOnly={field.isEdit == 0}
                        name={field.field}
                        value={field.value}
                        placeholder={field.fieldlabel}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </>
                  ) : field.datatype === "boolean" ? (
                    <select
                      className="form-control"
                      value={field.value}
                      disabled={field.isEdit == 0}
                      style={{
                        width: 120,
                      }}
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  ) : field.datatype === "date" ? (
                    <input
                      type="date"
                      required={field.isRequired == 1}
                      className="form-control"
                      readOnly={field.isEdit == 0}
                      name={field.field}
                      value={
                        field.value
                          ? moment(field.value).format("YYYY-MM-DD")
                          : ""
                      }
                      placeholder={field.field}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : (
                    <input
                      required={field.isRequired == 1}
                      className="form-control"
                      readOnly={field.isEdit == 0}
                      name={field.field}
                      value={field.value}
                      placeholder={field.fieldlabel}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  )}
                  {field.isError === "1" && (
                    <span className="errmsg">
                      {field.fieldlabel} is required
                    </span>
                  )}
                </div>
              </>
            ))}
          {ActionFormType == "formrow" && (
            // replacementValues.length > 0 ? (
            //   <></>
            // ) : (
            //   <div>Click OK to Perform Action</div>
            // )
            <div>Click OK to Perform Action</div>
          )}
        </div>
      </Modal>
      <Modal
        //title="Title"
        open={isError}
        onOk={async () => {
          setEditLoading(true);
          let response = await editHandler(
            payload_config["bucket_name"],
            payload["key"]
          );
          setEditLoading(false);
          if (response === true) {
            setEdit(false);
            getPayloads();
            setisError(false);
          } else {
            setEdit(true);
          }
        }}
        onCancel={handleCancelError}
        width={1000}
      >
        <div className="row">
          {PayloadRequired &&
            PayloadRequired.map((cell) => (
              <div className="col-md-2">
                <label>{cell["fieldname"]}</label>
                {checkfields(cell)}
              </div>
            ))}
        </div>
        <div className="mt-2">
          <span className="errmsg">
            Please Update Values for {isErrorValue}
          </span>
        </div>
      </Modal>
      {view_type == "table" && (
        <tr key={rowindex}>
          {updateUniqueData.length > 0 ? (
            <td>
              <>
                <input
                  //checked={CheckAll}
                  type="checkbox"
                  className="mobilecheckbox"
                  checked={isChecked}
                  value={
                    payload_config.field_mapping.filter(
                      (field) => field.useasinput === "1"
                    ) == true
                      ? payload[
                          payload_config.field_mapping
                            .filter((field) => field.useasinput === "1")
                            .map((field) => field.BucketField)
                        ]
                      : payload_config.payload_type == "M"
                      ? payload["_id.$oid"]
                        ? payload["_id.$oid"]
                        : payload._id.$oid
                      : payload["key"]
                  }
                  onChange={(e) => selectedMassUpdateData(e.target.value)}
                />
                {payload_config.view_type == "AV" && (
                  <div
                    class="table-elipse"
                    data-toggle="collapse"
                    data-target={"#" + rowindex}
                    onClick={() => {
                      handleRowClick(payload);
                      updaterowindex(rowindex);
                    }}
                  >
                    <span className="exprowbtn">+</span>
                  </div>
                )}
              </>
            </td>
          ) : (
            ""
          )}
          {(newfieldEdit_mapping || payload_config.field_mapping).map(
            (cell, tdindex) => {
              return (
                <>
                  {cell["isVisible"] == "1" && (
                    <td key={tdindex}>{checkfields(cell)}</td>
                  )}
                </>
              );
            }
          )}
          {/* <td>{moment(moment.unix(payload["created_at"])).fromNow()}</td> */}
          <td>
            <div className="payload_view_table_actions">
              <Actions
                disabled={disabled}
                defaultValue=""
                payload_config={payload_config}
                actionHandler={actionHandler}
                editHandler={editHandler}
                payload={payload}
                edit={edit}
                setEdit={setEdit}
                getPayloads={getPayloads}
                DeletePayload={DeletePayload}
                DeleteMongoPayload={DeleteMongoPayload}
                updatedPayload={updatedPayload}
                effective_designation_id={effective_designation_id}
                converttoarray={converttoarray}
                newfield_mapping={newfield_mapping}
                chkpayloadrequired={chkpayloadrequired}
                generate_modaldata={generate_modaldata}
                ActionFormType={ActionFormType}
                ModalForm={ModalForm}
                replacedFormrow={replacedFormrow}
                replacementValues={replacementValues}
                setModalForm={setModalForm}
                DisableBtn={DisableBtn}
                setDisableBtn={setDisableBtn}
                PayloadRequired={PayloadRequired}
                validatepayloaddata={validatepayloaddata}
                isError={isError}
                setisError={setisError}
                isErrorValue={isErrorValue}
                setisErrorValue={setisErrorValue}
                actionValue={actionValue}
                setisOpenModal={setisOpenModal}
                setactionValue={setactionValue}
                editLoading={editLoading}
                setEditLoading={setEditLoading}
                isVisibleEdit={isVisibleEdit}
              />
            </div>
          </td>
        </tr>
      )}

      {view_type == "mobile" && (
        <>
          {console.log("unique_groups", view_type)}
          {JSON.parse(grid_position.fielddata).showLabel == "1" && (
            <>{JSON.parse(grid_position.fielddata).fieldname} :</>
          )}

          {checkfields(JSON.parse(grid_position.fielddata), edit)}
          {
            (view_for = "mobbody" && rowindexbody + 1 == totcnt && (
              <>
                <div
                  className="payload_view_table_actions_mobile"
                  id={rowindex}
                >
                  <Actions
                    disabled={disabled}
                    defaultValue=""
                    payload_config={payload_config}
                    actionHandler={actionHandler}
                    editHandler={editHandler}
                    payload={payload}
                    edit={edit}
                    setEdit={setEdit}
                    getPayloads={getPayloads}
                    DeletePayload={DeletePayload}
                    DeleteMongoPayload={DeleteMongoPayload}
                    updatedPayload={updatedPayload}
                    effective_designation_id={effective_designation_id}
                    converttoarray={converttoarray}
                    newfield_mapping={newfield_mapping}
                    chkpayloadrequired={chkpayloadrequired}
                    generate_modaldata={generate_modaldata}
                    ActionFormType={ActionFormType}
                    ModalForm={ModalForm}
                    replacedFormrow={replacedFormrow}
                    replacementValues={replacementValues}
                    setModalForm={setModalForm}
                    DisableBtn={DisableBtn}
                    setDisableBtn={setDisableBtn}
                    PayloadRequired={PayloadRequired}
                    validatepayloaddata={validatepayloaddata}
                    isError={isError}
                    setisError={setisError}
                    isErrorValue={isErrorValue}
                    setisErrorValue={setisErrorValue}
                    actionValue={actionValue}
                    setisOpenModal={setisOpenModal}
                    setactionValue={setactionValue}
                    editLoading={editLoading}
                    setEditLoading={setEditLoading}
                    isVisibleEdit={isVisibleEdit}
                  />
                </div>
              </>
            ))
          }
        </>
      )}
      {/* {view_for == "action" && (
        
      )} */}
      {/* {expandedRow && isMobileView && (
        <div id={rowindex} class="cell-1 row-child">
          <DetailComponent
            detailData={expandedRow}
            payload_config={subpayload_config}
            api_token={api_token}
            effective_designation_id={effective_designation_id}
            fetch_onload={true}
          />
        </div>
      )} */}
      {expandedRow && !isMobileView && (
        <tr id={selectedrowindex} class="collapse cell-1 row-child">
          <td colSpan={5}>
            <DetailComponent
              detailData={expandedRow}
              payload_config={subpayload_config}
              api_token={api_token}
              effective_designation_id={effective_designation_id}
              fetch_onload={true}
              storesubdata={storesubdata}
              selectedrowindex={selectedrowindex}
              filtered_condition={filtered_condition}
              sub_data={sub_data||[]}
              removedCheckboxes={removedCheckboxes}
            />
          </td>
        </tr>
      )}
    </>
  );
}
function PaymentData({ payment }) {
  return (
    <tr>
      {Object.entries(payment.entity).map(([key, value]) => (
        <td key={key}>{value}</td>
      ))}
    </tr>
  );
}
function Actions({
  actionHandler,
  payload_config,
  payload,
  disabled,
  edit,
  setEdit,
  editHandler,
  getPayloads,
  DeletePayload,
  DeleteMongoPayload,
  updatedPayload,
  effective_designation_id,
  converttoarray,
  newfield_mapping,
  chkpayloadrequired,
  generate_modaldata,
  ActionFormType,
  ModalForm,
  replacedFormrow,
  replacementValues,
  setModalForm,
  DisableBtn,
  setDisableBtn,
  PayloadRequired,
  validatepayloaddata,
  isError,
  setisError,
  isErrorValue,
  setisErrorValue,
  actionValue,
  setisOpenModal,
  setactionValue,
  editLoading,
  setEditLoading,
  isVisibleEdit,
}) {
  const [actionLoading, setActionLoading] = useState(false);
  const [allowClear, setAllowClear] = useState(false);

  useEffect(() => {
    if (actionValue) {
      setisOpenModal(true);
    }
  }, [actionValue]);
  return (
    <>
      {payload_config.action_mapping[0].action_name &&
      effective_designation_id != "1" ? (
        <Select
          placeholder="Action"
          style={{
            width: 120,
          }}
          disabled={disabled}
          loading={actionLoading}
          options={[
            {
              label: "Select an action", // You can customize this label
              value: "", // An empty value
            },
            ...payload_config.action_mapping
              .map((action) => {
                if (
                  action.action_event != "onsave" &&
                  action.action_event != "massupdate"
                ) {
                  return { label: action.action_name, value: action.id };
                } else {
                  return null;
                }
              })
              .filter((item) => item !== null),
          ]}
          allowClear={allowClear}
          onChange={(value) => {
            setactionValue(value);
            value != "" && generate_modaldata(value, payload);
          }}
        />
      ) : (
        ""
      )}
      {!edit && isVisibleEdit && (
        <Button
          type="default"
          onClick={() => {
            chkpayloadrequired();
            setEdit(true);
          }}
          icon={<EditOutlined />}
        />
      )}
      {edit && !editLoading ? (
        <Button
          type="default"
          onClick={() => {
            setEdit(false);
            setDisableBtn(false);
          }}
          icon={<CloseOutlined />}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Button
          type="default"
          loading={editLoading}
          disabled={DisableBtn}
          icon={<CheckOutlined />}
          onClick={
            // () => {
            // let validatepayload = validatepayloaddata();
            // if (validatepayload) {
            async () => {
              let validatepayload = validatepayloaddata();

              if (validatepayload) {
                setEditLoading(true);
                let response = await editHandler(
                  payload_config["bucket_name"],
                  payload["key"]
                );
                setEditLoading(false);
                if (response === true) {
                  setEdit(false);
                  getPayloads();
                } else {
                  setEdit(true);
                }
              } else {
                //console.log("PayloadRequired", PayloadRequired);
              }
            }
            //}
            //}
          }
        />
      ) : (
        ""
      )}

      {effective_designation_id == 21 &&
        (payload_config.payload_type == "M" ? (
          <Button
            type="default"
            onClick={() => {
              if (window.confirm("Delete the item?")) {
                DeleteMongoPayload(payload._id.$oid);
              }
            }}
            icon={<DeleteOutlined />}
          />
        ) : (
          <Button
            type="default"
            onClick={() => {
              if (window.confirm("Delete the item?")) {
                DeletePayload(payload.key);
              }
            }}
            icon={<DeleteOutlined />}
          />
        ))}
    </>
  );
}
